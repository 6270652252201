import React, {FunctionComponent, useState} from 'react';
import {AppState, CaseStudy, syncQueryParams, UserState} from "../../AppState";
import ReactMapboxGl, {ZoomControl, GeoJSONLayer, Cluster, Marker} from 'react-mapbox-gl';
import {observer} from "mobx-react";
import * as MapboxGL from 'mapbox-gl';
import {metricsColors, uiColors} from "../../Palette";

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoidGFkaXJhbWFuIiwiYSI6IktzUnNGa28ifQ.PY_hnRMhS94SZmIR2AIgug',
    scrollZoom: true,
});

type MapViewProps = {
    appState: AppState,
    userState: UserState,
    activeLang: string,
    filteredCaseStudies: CaseStudy[],
}

const markerStyle = (background: string, color: string) => {
    return {
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: background,
        display: 'flex',
        color: color,//'white',
        justifyContent: 'center',
        alignItems: 'center',
        border: `2px solid ${metricsColors.cvg}`,
        cursor: 'pointer'
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    clusterMarker: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: metricsColors.cvg,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        border: `2px solid ${metricsColors.cvg}`,
        cursor: 'pointer'
    },
    markerSelected: markerStyle(uiColors['color_bg-dark'], 'white'),
    markerUnselected: markerStyle('white', uiColors['color_bg-dark']),
};

const MapView: FunctionComponent<MapViewProps> = ({appState, userState, activeLang, filteredCaseStudies}) => {
    // const [lat, setLat] = useState(0);
    // const [lng, setLng] = useState(0);
    // const [zoom, setZoom] = useState(2);

    let viewportState:any = {
        width: "100%",
        height: "100%",
        latitude: 0,
        longitude: 0,
        zoom: [1]
    };
    const [viewport, setViewPort ] = useState(viewportState)

    const _onViewportChange = (viewport:any) => setViewPort({...viewport, transitionDuration: 3000 })

    const onClickCluster = (evt: any) => {
        console.log('onClickCluster', evt);
    };
    const clusterMarker = (
        coordinates: GeoJSON.Position,
        pointCount: number,
        getLeaves: (
            limit?: number,
            offset?: number
        ) => Array<React.ReactElement<any>>
    ) => (
        <Marker
            key={coordinates.toString()}
            coordinates={coordinates}
            style={styles.clusterMarker}
            onClick={onClickCluster}
        >
            <div>{pointCount}</div>
        </Marker>
    );

    //https://blog.logrocket.com/how-to-use-mapbox-gl/
    return <div className={'case-studies--map'}>
        <Map
            style="mapbox://styles/tadiraman/ckggn6li57aos19pgszuppmsv"
            containerStyle={{}}
            {...viewport}
            onViewportChange={_onViewportChange}

            // center={[lng, lat]}
            // zoom={[zoom]}
            // onMove={(map, event) => {
            //     setLng(map.getCenter().lng);
            //     setLat(map.getCenter().lat);
            //     setZoom(map.getZoom());
            // }}
            // onZoomEnd={(map, event) => {
            //     setZoom(map.getZoom())
            //     let center = map.getCenter();
            //     setLat(center.lat);
            //     setLng(center.lng);
            // }}
            // onDragEnd={(map, event) => {
            //     let center = map.getCenter();
            //     setLat(center.lat);
            //     setLng(center.lng);
            // }}
        >
            <ZoomControl/>
            <GeoJSONLayer
                data={appState.geojsonPoygons}
                linePaint={{
                    'line-color': metricsColors.cvg,
                    'line-opacity': 0.5,
                    'line-width': 3,
                }}/>
            <Cluster ClusterMarkerFactory={clusterMarker}>
                {appState.geojsonPoints.features.map((feature: any, key: number) => (
                    <Marker
                        key={key}
                        style={feature.properties.selected ? styles.markerSelected : styles.markerUnselected}
                        coordinates={feature.geometry.coordinates}
                        data-feature={feature}
                        onClick={() => {
                            appState.toggleSelectedCaseStudyById(feature.properties.uid)
                        }}
                    >
                        <div title={feature.properties.name}>
                            {''}
                        </div>
                    </Marker>
                ))}
            </Cluster>
        </Map>
    </div>;
}

export default observer(MapView);
