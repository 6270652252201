import React from 'react';
import {observer} from "mobx-react";
import {AppState, ContentBlockModel, UserState} from "../AppState";
import Repeater from "../editables/Repeater";
import ContentSection from "./contentBlocks/ContentSection";
import Footer from "./Footer";

@observer
export default class About extends React.Component<{ appState: AppState, userState: UserState }, {}> {
    render() {
        const {appState, userState} = this.props;
        return (
            <div className="About main-page-parent">
                <div className="main-page">
                    <Repeater list={appState.aboutContent}
                              create={() => new ContentBlockModel({})}
                              eachRender={(contentBlockModel, index) =>
                                  <ContentSection
                                      userState={userState}
                                      appState={appState}
                                      key={`about_contentSection_${contentBlockModel.uid}`}
                                      contentBlock={contentBlockModel}/>
                              }/>
                    <Footer appState={appState}/>
                </div>
            </div>
        );
    }
}
