import React, {FunctionComponent} from 'react';
import {observer} from "mobx-react";
import {LookupLinks, LookupList} from "./EditableStates";
import SimpleSelect from "../components/core/SimpleSelect";

type LinkedLookupProps = {
    editMode: boolean;
    lang: string,
    lookups: LookupLinks,
    list: LookupList,
    field: string,
    defaultValue?: string,
}

const randomString = (length:number) => [ ...Array(length) ].map(() => (~~(Math.random() * 36)).toString(36)).join('');

const LinkedLookup: FunctionComponent<LinkedLookupProps> = ({editMode, lang, list, lookups, field, defaultValue}) => {
    if (!editMode) {
        return <span>{lookups.get(list, lang, field)}</span>
    }
    const setter = (selectedVal:string) => {
        lookups.set(field, selectedVal);
    };
    return <SimpleSelect
        value={lookups.getKey(field)}
        setter={setter}
        onAdd={(option: string) => {
            const key = randomString(14);
            list.setLink(lang, field, key, option);
            setter(key);
        }}
        options={list.getOptions(lang, field)}
    />
};

export default observer(LinkedLookup);
