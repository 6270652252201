import React, {MouseEventHandler} from 'react';
import {motion} from "framer-motion";
import {observer} from "mobx-react";
import {AppState, CaseStudy, syncQueryParams} from "../../AppState";
import EditableText from "../../editables/EditableText";
import EditableImage from "../../editables/EditableImage";
import LinkedLookup from "../../editables/LinkedLookup";
import EditableRichText from '../../editables/EditableRichText';
import MetricDisplay from "../core/MetricDisplay";
import {CardMetric, CardTags, ExpandButton} from "./_Atoms";
import MetricsVis from "../metrics/MetricsVis";
import {NavLink} from "react-router-dom";
import FigureGround from "./FigureGround";


@observer
export default class CompareView extends React.Component<{ appState: AppState, list: CaseStudy[], editMode: boolean, lang: string }, {}> {

    componentDidMount() {
        syncQueryParams(this.props.appState);
    }

    render() {
        const {appState, list, editMode, lang} = this.props;

        return (
            <div className={'case-studies--compare'}>
                <div className={"CompareView explore-content"}>
                    <div className={"row hero"}>
                        {list.map(caseStudy =>
                            <div className={"hero-image"}>
                                <ExpandButton size={24} color={"#ffffff"} appState={appState} caseStudy={caseStudy}/>
                                <motion.div layoutId={`${caseStudy.uid}_hero`}>
                                    <EditableImage urlPrefix={appState.imageUrlPrefix} lang={appState.activeLang} imageHandler={appState.imageHandler}
                                                   editMode={editMode} imageState={caseStudy.getHeroImage()}/>
                                </motion.div>
                            </div>
                        )}
                    </div>
                    <div className={"row"}>
                        {list.map(caseStudy =>
                            <div className={"Card__content"}>
                                <motion.div layoutId={`${caseStudy.uid}_name_wrapper`} layout>
                                    <NavLink to={`/explore/details/${encodeURIComponent(caseStudy.uid)}`}>
                                        <motion.h3 className={'anim-text'} layoutId={`${caseStudy.uid}_name`} layout>
                                            <EditableText
                                                editMode={editMode}
                                                lang={lang}
                                                map={caseStudy.textValues}
                                                field="name"
                                                defaultValue="Enter Case Study Name"/>
                                        </motion.h3>
                                    </NavLink>
                                </motion.div>
                            </div>
                        )}
                    </div>
                    <div className={"row"}>
                        {list.map(caseStudy =>
                            <motion.div layoutId={`${caseStudy.uid}_location`} className="Card__location" layout>
                                <motion.div className={'anim-text'} layout>
                                    <LinkedLookup editMode={editMode} list={appState.linkedObjects} lang={lang}
                                                  lookups={caseStudy.lookups} field="location"/>
                                </motion.div>
                            </motion.div>
                        )}
                    </div>
                    <div className={"row"}>
                        {list.map(caseStudy =>
                            <CardTags lang={lang} editMode={editMode} appState={appState} caseStudy={caseStudy}/>
                        )}
                    </div>
                    <div className={"row"}>
                        {list.map(caseStudy =>
                            <div className={"Card__metrics"}>
                                {appState.metricFormatters.map(metric => (
                                    <CardMetric metric={metric} appState={appState} caseStudy={caseStudy}
                                                editMode={editMode}/>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={"row isometric"}>
                        {list.map(caseStudy =>
                            <div className={"graphic"}>
                                <MetricsVis bldgMode={'center'}
                                            width={250}
                                            cvg={caseStudy.CVG}
                                            dud={caseStudy.dud}
                                            far={caseStudy.FAR}
                                            pop={caseStudy.pop}/>
                            </div>
                        )}
                    </div>
                    <div className={"row plan"}>
                        {list.map(caseStudy =>
                            <FigureGround width={'260px'} appState={appState} spatialData={caseStudy.spatialData}/>
                        )}
                    </div>
                    {/*<div className={"row"}>*/}
                    {/*    {list.map(caseStudy =>*/}
                    {/*        <div className={"Card__text"}>*/}
                    {/*            <EditableRichText editMode={editMode} lang={lang} map={caseStudy.textValues}*/}
                    {/*                              field="content" defaultValue="Case study content"/>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                </div>
            </div>
        );
    }
}
