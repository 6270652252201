import React, {FunctionComponent} from 'react';
import {observer} from "mobx-react";
import {LangMap} from "./EditableStates";
import EditableSpan from "./EditableSpan";

type EditableTextProps = {
    editMode: boolean;
    lang: string,
    map: LangMap,
    field: string,
    defaultValue?: string,
}

const EditableText: FunctionComponent<EditableTextProps> = ({editMode, lang, map, field, defaultValue}) => {

    return <EditableSpan editMode={editMode} defaultValue={defaultValue} getter={() => {
        return map.get(lang, field);
    }} setter={(val) => {
        map.set(lang, field, val);
    }}/>
};

export default observer(EditableText);
