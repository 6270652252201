import React, {useEffect} from 'react';
import './App.scss';
import {UserState, AppState, PageMode} from "./AppState";
import {observer} from "mobx-react";
import {UndoManager} from "mobx-keystone/dist/actionMiddlewares/undoMiddleware";

import {
    Router,
    Switch,
    Route,
    useLocation,
} from "react-router-dom";
import Home from "./components/Home";
import Measure from "./components/Measure";
import Learn from "./components/Learn";
import Explore from "./components/explore/Explore";
import About from './components/About';
import EditorToolbar from "./editables/toolbars/EditorToolbar";
import Persistence from "./editables/Persistence";
import NavBar from './components/NavBar';
import DataManager, {IDataManager} from "./data/DataManager";
import Login from "./components/Login";

@observer
export default class App extends React.Component<{ userState: UserState, appState: AppState, undoManager: UndoManager, dataManager: IDataManager }, {}> {
    render() {
        const {appState, userState, undoManager, dataManager} = this.props;

        if (!appState.loaded || !appState.history) {
            return <div className={'loading-notice'}>Loading...</div>
        }
        if (appState.editableMode && !appState.user) {
            return <Login appState={appState}/>;
        }
        //Note: Route needed for inner NavLinks even though we're not using Routes here
        return (<Router history={appState.history}>
            {appState.editableMode &&
            <Persistence undoManager={undoManager} appState={appState} userState={userState} dataManager={dataManager}/>}
            <NavBar appState={appState}/>

            {userState.pageMode === PageMode.Home && <Home appState={appState}/>}
            {userState.pageMode === PageMode.About && <About userState={userState} appState={appState}/>}
            {userState.pageMode === PageMode.Measure && <Measure userState={userState} appState={appState}/>}
            {userState.pageMode === PageMode.Learn && <Learn userState={userState} appState={appState}/>}
            {userState.pageMode === PageMode.Explore && <Explore userState={userState} appState={appState}/>}

            <EditorToolbar visible={true}/>
        </Router>);
    }
}
