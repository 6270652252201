import React, {
    ChangeEvent,
    Fragment,
    FunctionComponent,
    KeyboardEvent,
    KeyboardEventHandler,
    useRef,
    useState
} from 'react';
import {observer} from "mobx-react";
import {motion} from "framer-motion";
import RadioSlider from '../RadioSlider';
import {AppState, UserState, Unit, ViewMode, CaseStudy, FilterState, ScaleIds} from "../../AppState";
import {IOption} from "../../editables/EditableStates";
import {NavLink} from "react-router-dom";
import EditableText from "../../editables/EditableText";
// import lunr from "lunr";

type SearchBoxProps = {
    filter: FilterState
}

const SearchBox: FunctionComponent<SearchBoxProps> = ({filter}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [userInput, setUserInput] = useState(filter.searchQuery);

    return (<div className="search-box">

        <input ref={inputRef}  placeholder='...'
               value={userInput}

               onChange={(e: ChangeEvent<HTMLInputElement>) => {
                   setUserInput(e.target.value);
               }}

               onKeyPress={(evt)=> {
                   if (evt.key === 'Enter') {//spans don't work with multiple lines, enter should 'complete' the edit
                       evt.preventDefault();
                       if (inputRef.current) inputRef.current.blur();
                   }
               }}

               onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                   filter.searchQuery = userInput
               }}/>
        <img src="/img/search.svg" alt="Search" className="search-box__icon"/>
    </div>);
};

@observer
export default class FilterPanel extends React.Component<{ appState: AppState, userState: UserState, compareMode: boolean }, {}> {
    idx: any = '';

    reset() {
        const {appState, userState} = this.props;
        const {filter} = userState;

        filter.searchQuery = '';
        filter.scale = 'POP';
        filter.metric = ScaleIds.Neighborhood;
        userState.unit = Unit.Hectare;

        appState.clearSelection();
        userState.navigateDefault();
    }

    render() {
        const {appState, userState, compareMode} = this.props;
        const {filter} = userState;
        const {activeLang, caseStudies, includedCaseStudies, caseStudiesByMetric, selectedCaseStudies} = appState;

        const text = (defaultValue: string) => {
            return <EditableText map={appState.terms} editMode={appState.editableMode} lang={appState.activeLang}
                                 defaultValue={defaultValue} field={defaultValue.toLowerCase().split(' ').join('-')}/>
        }

        return (
            <div className="FilterPanel">
                <div className="filter-panel-scroll">
                    <div className="FilterPanel__section">
                        <h4>{text('View Mode')}</h4>

                        <NavLink activeClassName='is-active' to="/explore/cards">
                            <img className={'nav-image'} alt="View Cards" title="View Cards" src='/img/CardMode.svg'/>
                        </NavLink>
                        <NavLink activeClassName='is-active' to="/explore/rows">
                            <img className={'nav-image'} alt="View Rows" title="View Rows" src='/img/RowMode.svg'/>
                        </NavLink>
                        <NavLink activeClassName='is-active' to="/explore/map">
                            <img className={'nav-image'} alt="View Map" title="View Map" src='/img/MapMode.svg'/>
                        </NavLink>
                    </div>


                    {selectedCaseStudies.length > 0 && (
                        <motion.div layoutId={'filterPanel-compare-list'} className="FilterPanel__section compare-list">
                            <div className="compare-list__buttons">
                                <NavLink activeClassName='is-active' to="/explore/compare">
                                    {text('Compare')}
                                    {selectedCaseStudies.length > 1 && (
                                        <span className="compare-count">{selectedCaseStudies.length}</span>
                                    )}
                                </NavLink>
                                {/*<button className="tag-bubble" onClick={() => userState.viewMode = ViewMode.Compare}>CLEAR</button>*/}
                            </div>
                            <ul>
                                {selectedCaseStudies.map((caseStudy: CaseStudy) => (
                                    <li key={caseStudy.uid}>
                                        <div>{caseStudy.textValues.get(activeLang, 'name')}</div>
                                        <button onClick={() => appState.toggleSelectedCaseStudy(caseStudy)}>X</button>
                                    </li>
                                ))}
                            </ul>


                        </motion.div>
                    )}

                    {!compareMode && <Fragment>
                        <div className="FilterPanel__section">
                            <h4>{text('Search')}</h4>

                            <SearchBox filter={filter}/>
                        </div>

                        <div className="FilterPanel__section">
                            <h4>{text('Scale')}</h4>

                            <div className="metric">
                                <div className={`tag-bubble ${!filter.metric ? 'tag-bubble--active' : ''}`}
                                     onClick={() => filter.metric = null}>{text('All')}</div>
                                {includedCaseStudies.length}
                            </div>
                            {appState.scaleOptions.map((s: IOption) =>
                                <div key={s.value} className="metric">
                                    {caseStudiesByMetric[s.value] && <Fragment>
                                        <div
                                            className={`tag-bubble ${filter.metric === s.value ? 'tag-bubble--active' : ''}`}
                                            onClick={() => filter.metric = s.value}>{s.label}
                                        </div>
                                        {caseStudiesByMetric[s.value].length}
                                    </Fragment>
                                    }
                                </div>
                            )}
                        </div>

                        {filter.metric &&
                        <div className="FilterPanel__section">
                            <h4>{text('Metric (Sort)')}</h4>

                            <RadioSlider<string> value={filter.scale} options={['POP', 'DUD', 'FAR', 'CVG']}
                                                 onChange={(scale: string) => filter.scale = scale}/>
                        </div>
                        }
                    </Fragment>}

                    <motion.div layoutId={'filterPanel-section'} className="FilterPanel__section">
                        <h4>{text('Units')}</h4>

                        <RadioSlider<Unit> value={userState.unit} options={[Unit.Hectare, Unit.Acre]}
                                           onChange={(unit: Unit) => userState.unit = unit}/>
                    </motion.div>

                    <motion.div layoutId={'filterPanel-clear-all'} className="FilterPanel__section">
                        <button className="tag-bubble" onClick={() => this.reset()}>{text('Reset')}</button>
                    </motion.div>
            </div>
    </div>
        );
    }

}
