import React, {MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import {AppState, ContentBlockModel, ImageModel} from "../../AppState";
import EditableImage from "../../editables/EditableImage";
import Repeater from "../../editables/Repeater";

@observer
export default class ImageBlock extends React.Component<{appState: AppState, contentBlock: ContentBlockModel, onMouseEnter?:MouseEventHandler, onMouseLeave?:MouseEventHandler}, {}> {
    render() {
        const {contentBlock, onMouseEnter, onMouseLeave, appState} = this.props;
        return (
            <div id={contentBlock.uid} className="ImageBlock container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="row">
                    <div className="col">
                        <div className="imageList">
                            <Repeater list={contentBlock.imageState}
                                      create={() => new ImageModel({})}
                                      eachRender={(imageModel, index) =>
                                          <EditableImage urlPrefix={appState.imageUrlPrefix} lang={appState.activeLang} imageHandler={appState.imageHandler} editMode={appState.editableMode} key={`learn_image_${imageModel.uid}`} width={150} height={100} imageState={imageModel.imageState}/>
                                      }/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
