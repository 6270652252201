import React, {MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import ReactMapboxGl, {Layer, Feature, ZoomControl, GeoJSONLayer} from 'react-mapbox-gl';
import {AppState} from "../../AppState";
import {metricsColors} from "../../Palette";


@observer
export default class FigureGround extends React.Component<{ appState: AppState, spatialData: any, width?: string, height?: string }, {}> {

    getData = (geometry: any) => {
        if(geometry){
            const data = {
                "type": "FeatureCollection",
                "features": [
                    {
                        "type": "Feature",
                        "properties": {},
                        "geometry": geometry
                    }
                ]
            };
            return data;
        }
    }


    render() {
        const {appState, spatialData} = this.props;
        let width = this.props.width || 'auto'
        let height = this.props.height || '40vw'

        const Map = ReactMapboxGl({
            accessToken: 'pk.eyJ1IjoidGFkaXJhbWFuIiwiYSI6IktzUnNGa28ifQ.PY_hnRMhS94SZmIR2AIgug',
            scrollZoom: false,
        });
        return (
            <div className="FigureGround">
                <Map
                    style="mapbox://styles/tadiraman/ckggn6li57aos19pgszuppmsv"
                    pitch={[30]}
                    containerStyle={{
                        height: height,
                        width: width
                    }}
                    fitBounds={spatialData.bounds}
                    center={[spatialData.center.lng, spatialData.center.lat]}
                    zoom={[15]}
                >
                    <ZoomControl/>
                    <GeoJSONLayer
                        data={this.getData(spatialData.geometry)}
                        linePaint ={{
                            'line-color': metricsColors.cvg,
                            'line-opacity': 0.75,
                            'line-width': 3,
                        }}/>
                </Map>
            </div>
        );
    }
}
