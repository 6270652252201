import React, {FunctionComponent} from "react";

const Modal: FunctionComponent<{
    title?: string,
}> = ({title, children}) => {

    return <div className={'Modal'}>{children}</div>
}

export default Modal;
