import React from 'react';
import {observer} from "mobx-react";
import {AppState, ImageModel} from "../AppState";

import EditableImage from "../editables/EditableImage";
import {EditableToolbarState} from "../editables/EditableToolbarState";
import EditableText from "../editables/EditableText";

import Carousel from 'react-bootstrap/Carousel'

interface IProps {
    appState: AppState,
    list: ImageModel[]
}

interface IState {
    value: number;
}


@observer
export default class Slider extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            value: 0
        }

        this.onChange = this.onChange.bind(this);
    }

    onChange(val: number) {
        this.setState({
            value: val
        });
    }

    render() {
        const {appState, list} = this.props;

        return (
            <div className="Slider">
                <div className={"title"}>
                    <EditableText editMode={appState.editableMode} lang={appState.activeLang} map={appState.textValues}
                                  field="title"/>
                </div>

                <Carousel
                    controls={false}
                    fade={true}
                    pause={false}
                    className="carousel-fade"
                >
                    {list && list.map((img, i) => <Carousel.Item key={img.uid}>
                        <div
                            className="carouselImage"
                            style={{backgroundImage: `url(${appState.imageUrlPrefix}${img.imageState.urls.get('large')})`}}
                        />
                        <h4 className={"caption"}>
                            <EditableText editMode={appState.editableMode} lang={appState.activeLang} map={img.imageState.textValues} field="caption" defaultValue="Source:"/>
                        </h4>
                    </Carousel.Item>)}
                </Carousel>

                <img className="scale" alt="scale" src={"./img/scale.svg"} />

                {appState.editableMode &&
                <div className={"images"}>
                    <ul>
                        {list && list.map((img, i) => {
                            return <li>
                                <button onClick={() => {
                                    EditableToolbarState.getInstance().removeElement(list, img)
                                }
                                }>
                                    X
                                </button>
                                <EditableImage urlPrefix={appState.imageUrlPrefix} lang={appState.activeLang} displaySize={'small'} imageHandler={appState.imageHandler} editMode={appState.editableMode}
                                               key={'carouselImage_' + i} imageState={img.imageState}/>
                            </li>
                        })}
                        <li>
                            <div className={"addImage"} onClick={() => {
                                EditableToolbarState.getInstance().insert(list, new ImageModel({}));
                            }
                            }>
                                <span>Add New Slider Image</span>
                                <span className={"plus"}>
                                    &#43;
                                </span>
                            </div>
                        </li>
                    </ul>

                </div>
                }
            </div>
        );
    }
}
