import React, {MouseEventHandler, useEffect} from 'react';
import {motion} from "framer-motion";
import {observer} from "mobx-react";
import {AppState, UserState, CaseStudy} from "../../AppState";
import EditableText from "../../editables/EditableText";
import EditableImage from "../../editables/EditableImage";
import LinkedLookup from "../../editables/LinkedLookup";
import {CardMetric, CardTags, CheckBox, ExpandButton} from "./_Atoms";
import {NavLink} from "react-router-dom";


@observer
export default class Card extends React.Component<{ appState: AppState, userState: UserState, editMode: boolean, lang: string, caseStudy: CaseStudy, onMouseEnter?: MouseEventHandler, onMouseLeave?: MouseEventHandler }, {}> {
    render() {
        const {appState, editMode, lang, caseStudy, onMouseEnter, onMouseLeave, userState} = this.props;
        const {textValues, lookups, uid} = caseStudy;

        const heroImage = caseStudy.getHeroImage();

        if (userState.modalId === caseStudy.uid) {//don't render this layoutId twice as frame motion gets confused
            console.log('userState.modalId MATCH ', userState.modalId)
            return <div className="Card explore-content"/>
        }

        console.log('RENDER CARD');

        return (
            <motion.div layoutId={uid} className="Card explore-content" onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}>
                <motion.div layoutId={`${uid}_hero`}>
                    <EditableImage urlPrefix={appState.imageUrlPrefix} lang={appState.activeLang}
                                   imageHandler={appState.imageHandler} editMode={editMode}
                                   imageState={heroImage}/>
                </motion.div>

                {editMode && userState.showDraftCaseStudies && <div>
                    <label>
                        <input type="checkbox" checked={caseStudy.draftMode}
                               onClick={() => caseStudy.draftMode = !caseStudy.draftMode}/>Draft
                    </label>
                </div>}

                <div className="content">
                    <motion.div layoutId={`${uid}_name_wrapper`} layout>
                        <NavLink to={`/explore/details/${encodeURIComponent(caseStudy.uid)}`}>
                            <motion.h3 className={'anim-text fixed-height'} layoutId={`${uid}_name`} layout>
                                <EditableText editMode={editMode} lang={lang}
                                              map={textValues}
                                              field="name"
                                              defaultValue="Enter Case Study Name"/>
                            </motion.h3>
                        </NavLink>
                    </motion.div>
                    <motion.div layoutId={`${uid}_tag_location`} className="location" layout>
                        <motion.div layout>
                            <LinkedLookup editMode={editMode} list={appState.linkedObjects} lang={lang}
                                          lookups={lookups}
                                          field="location"/>
                        </motion.div>
                    </motion.div>

                    <CardTags lang={lang} editMode={editMode} appState={appState} caseStudy={caseStudy}/>

                    <div className="metrics">
                        {appState.metricFormatters.map((metric) => (
                            <CardMetric key={metric.subtext} metric={metric} appState={appState} caseStudy={caseStudy}
                                        editMode={editMode}/>
                        ))}
                    </div>

                </div>
                <div className={`checkbox`}>
                    <CheckBox appState={appState} caseStudy={caseStudy}/>
                </div>
                <ExpandButton size={24} color={"#ffffff"} appState={appState} caseStudy={caseStudy}/>
            </ motion.div>
        );
    }
}
