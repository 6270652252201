import React, {MouseEventHandler, Fragment} from 'react';
import {observer} from "mobx-react";
import {AppState, ContentBlockModel, ImageModel} from "../../AppState";
import EditableText from "../../editables/EditableText";
import EditableImage from "../../editables/EditableImage";
import EditableRichText from "../../editables/EditableRichText";
import Repeater from "../../editables/Repeater";

@observer
export default class ListContentBlock extends React.Component<{appState: AppState, contentBlock: ContentBlockModel, onMouseEnter?:MouseEventHandler, onMouseLeave?:MouseEventHandler}, {}> {
    render() {
        const {contentBlock, onMouseEnter, onMouseLeave, appState} = this.props;
        return (
            <div id={contentBlock.uid} className="ListContentBlock container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="row">
                    <div className="col-sm-4">
                        <h1><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={contentBlock.textValues} field="header" defaultValue=""/></h1>
                    </div>
                    <div className="col-sm-8">
                        <h1><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={contentBlock.textValues} field="title" defaultValue=""/></h1>
                    </div>
                </div>
                <div className="ListContentBlock_body">
                    <Repeater list={contentBlock.imageState}
                              create={() => new ImageModel({})}
                              eachRender={(imageModel, index) =>
                                  <div className="ListContentBlock_item row" key={`ListContentBlock_item_${imageModel.uid}`}>
                                      <div className="col-sm-4">
                                          <EditableImage urlPrefix={appState.imageUrlPrefix} lang={appState.activeLang} imageHandler={appState.imageHandler} editMode={appState.editableMode} displaySize={'small'} width={150} height={100} imageState={imageModel.imageState}/>
                                      </div>
                                      <div className="col-sm-8">
                                          <h4>
                                              <EditableText editMode={appState.editableMode} lang={appState.activeLang} map={imageModel.textValues} field="subheader" defaultValue=""/>
                                          </h4>
                                          <p>
                                              <EditableRichText editMode={appState.editableMode} lang={appState.activeLang} map={imageModel.textValues} field="body" defaultValue=""/>
                                          </p>
                                      </div>
                                  </div>
                              }/>
                </div>
            </div>
        );
    }
}
