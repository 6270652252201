import React from 'react';
import {observer} from "mobx-react";
import firebase from 'firebase';
import {AppState} from "../AppState";
import logo from '../assets/logo.png';

@observer
export default class Login extends React.Component<{appState: AppState }, {}> {

    render() {
        const {appState} = this.props;
        const provider = new firebase.auth.GoogleAuthProvider();

        const handleSignIn = () => {
            firebase.auth().signInWithPopup(provider);
        };
        return (
            <div className="Login">
                <div>
                    <img src={logo} alt="Programming Tool" />
                    <h1>Density Atlas</h1>

                    <p>Please login with your Sasaki account</p>

                    <button
                        type="button"
                        className="login"
                        onClick={handleSignIn}
                    >
                        Login with Google
                    </button>
                </div>
            </div>
        );
    }
}
