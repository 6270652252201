import React, {FunctionComponent} from "react";
import CreatableSelect from 'react-select/creatable'
import {IOption} from "../../editables/EditableStates";
import Select from "react-select";

const SimpleSelect: FunctionComponent<{
    value?: string,
    onAdd?: (option: string) => void,
    setter: (selectedVal: string) => void,
    options: Array<IOption>
}> = ({onAdd, value, setter, options}) => {
    const selectedOption = options.find(option => option.value === value);

    if (onAdd) {
        return <CreatableSelect
            value={selectedOption}
            onCreateOption={(inputValue: any) => {
                if (onAdd) {
                    onAdd(inputValue);
                }
            }}
            onChange={(selectedOption: any) => {
                // console.log(selectedOption.value);
                setter(selectedOption.value);
            }}
            options={options}
        />
    }

    return <Select
        value={selectedOption}
        onChange={(selectedOption: any) => {
            setter(selectedOption.value);
        }}
        options={options}
    />
}

export default SimpleSelect;
