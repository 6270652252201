import React, {FunctionComponent, useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone'
import {observer} from "mobx-react";
import {ImageState} from "./EditableStates";
import IImageHandler from "./data/IImageHandler";
import EditableText from "./EditableText";

type ImageStateProps = {
    displaySize: string;
    imageState: ImageState,
    imageHandler: IImageHandler | undefined,
    width?: number,
    height?: number,
}
type EditableImageProps = {
    editMode: boolean;
    displaySize?: string;
    className?: string;
    urlPrefix: string;
    lang: string;
    imageState: ImageState,
    imageHandler: IImageHandler | undefined,
    width?: number,
    height?: number,
}

const FileDropZone: FunctionComponent<ImageStateProps> = ({displaySize, imageHandler, imageState, width, height}) => {
    const [processing, setProcessing] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file: any) => {
            setProcessing(true);
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = async (e) => {
                // Do whatever you want with the file contents
                const arrayBuffer = reader.result as ArrayBuffer;
                // let dataUrlStr = String(dataUrl);
                if (imageHandler) {
                    const imageObj = await imageHandler.processImage(arrayBuffer, file.type.split('/')[1]);
                    imageState.urls.setMultiple(imageObj);
                    setProcessing(false);
                }

            };
            // reader.readAsDataURL(file);
            reader.readAsArrayBuffer(file);
        })

    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});


    if (!imageState) {
        return (<div style={{width: width, height: height}}>No Image State Set</div>)
    }

    let classNames = 'FileDropZone';
    if (!imageState.urls.get(displaySize)) {
        classNames += ' blank';
    }
    return (
        <div className={classNames} style={{width: width, height: height}} {...getRootProps()}>
            <input {...getInputProps()} />
            {
                processing ?
                    <p>
                        <div className="processing-wait"/>
                        <span>Processing, please wait...</span>
                    </p> :
                    isDragActive ?
                        <p><span>Drop the files here ...</span></p> :
                        <p><span>Drag 'n' drop some files here, or click to select files</span></p>
            }
        </div>
    )
};


const EditableImage: FunctionComponent<EditableImageProps> = ({className, lang, displaySize = 'medium', imageHandler, editMode, imageState, urlPrefix}) => {
    let displayUrl = imageState ? imageState.urls.get(displaySize) : null;
    if (urlPrefix) {
        displayUrl = urlPrefix + displayUrl;
    }
    let classNameApplied = 'EditableImage';
    if (className) {
        classNameApplied += ' ' + className;
    }
    return (<div className={classNameApplied}>
        {displayUrl && <img src={displayUrl}
                            alt={imageState?.textValues.get(lang, 'alt')}/>}
        {imageState &&
        <div className="caption"><EditableText editMode={editMode} lang={lang} map={imageState.textValues}
                                               field="caption" defaultValue="Source:"/></div>}
        {editMode && <FileDropZone displaySize={displaySize} imageHandler={imageHandler} imageState={imageState}/>}
        {editMode && <div className="editable-image-text">

        </div>}
    </div>)
};

export default observer(EditableImage);
