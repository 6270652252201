import {AppState, DocRef} from "../AppState";
import firebase from 'firebase';
import firebaseConfig from "./../config";
import {applySnapshot, fromSnapshot, getSnapshot} from "mobx-keystone";

export interface IDataManager {
    saveCurrentState: () => void,
    reload: () => void,
}

abstract class FireStoreHandler {
    protected database: firebase.firestore.Firestore;
    protected databaseRef: firebase.firestore.DocumentReference;

    constructor(collectionId: string, documentId: string) {
        if (firebase.apps.length === 0) {
            firebase.initializeApp(firebaseConfig);
            firebase.analytics();
            firebase.analytics().logEvent('initialized');
        }
        this.database = firebase.firestore();
        this.databaseRef = this.database.collection(collectionId).doc(documentId);
    }

    saveChanges<T>(snapshot: T) {
        if (firebase.auth().currentUser) {
            const saveData = JSON.parse(JSON.stringify(snapshot));
            this.databaseRef.set(saveData)
                .then((docRef) => {
                    // this.databaseRef.update({updateVersion: increment});
                    console.log("Document written");
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
        } else {
            //console.log('user not logged in');
        }
    }

    protected abstract applySnapshot(data: any): void;

    protected abstract getSnapshot(): any;

    saveCurrentState() {
        this.saveChanges(this.getSnapshot());
    }

    reload() {
        this.databaseRef.get().then((doc) => {
            if (doc.exists) {
                let documentData = doc.data();
                this.applySnapshot(documentData);
                // console.log('RELOADED FROM DB: ' + getSnapshot(this.appState));
            }
        });
    }

    initializeListeners(callback: () => void) {
        //NOTE auth not needed for 'get' only 'set'
        this.databaseRef.get().then((doc) => {
            if (doc.exists) {
                let documentData = doc.data();
                // if (!this.validVersion(documentData)) return;
                this.applySnapshot(documentData);
                // applySnapshot(this.appState, this.preprocessData(documentData));
                // this.onLoadFromFirebase();
                // console.log('UPDATED FROM DB: ' + getSnapshot(this.appState));
            } else {
                //console.log("No such document!");
                this.saveChanges(this.getSnapshot());//doesn't exist yet - so serialize the defaults (even if no changes detected yet)

            }
            callback();

        }).catch(function (error) {
            console.log("Error getting document:", error);
        });

    }
}


export class DocRefManager extends FireStoreHandler {
    private docRef: DocRef;
    private snapshot: any;

    constructor(docRef: DocRef) {
        super(docRef.collectionName, docRef.documentId);
        this.docRef = docRef;

    }

    async getContent() {
        return new Promise((resolve, reject) => {
            this.initializeListeners(() => {
                this.docRef.loaded = true;
                resolve(this.snapshot);
            });
        });

    }

    protected getSnapshot() {
        return getSnapshot(this.docRef.data)
    }

    protected applySnapshot(data: any): void {
        // this.docRef.applySnapshot(data);
        this.snapshot = data;
    }
}

export default class DataManager extends FireStoreHandler implements IDataManager {
    private appState: AppState;

    constructor(appState: AppState) {
        // super('documents', 'draft');
        super('documents', '_dryrun');
        this.appState = appState;
        this.initializeListeners(() => {
            this.appState.loaded = true;
        });
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.appState.user = user.toString();
                //Look in the firestore for the document and load it if it exists
            }
        });
    }

    preprocessData(data: any) {
        if (!data) return data;
        //optional
        return data;
    }

    protected getSnapshot() {
        return getSnapshot(this.appState)
    }

    protected applySnapshot(data: any): void {
        this.appState.applySnapshot(this.preprocessData(data));
    }
}
