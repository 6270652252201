import {AppState} from "../AppState";
import firebase from 'firebase';
import IImageHandler from "../editables/data/IImageHandler";
import Jimp from 'jimp';
import md5 from 'md5';

function repath(value: string): string {//using this to strip off the firebasestorage specific URLs
    if (value.indexOf('firebasestorage')) {
        console.log(value);
    }

    value = value.replace('https://firebasestorage.googleapis.com/v0/b/densityatlas-b3dbd.appspot.com/o/images%2F', '');
    return value.split('?alt=media&token=')[0];
}

export default class ImageHandler implements IImageHandler {
    private appState: AppState;
    private storageRef?: firebase.storage.Reference;

    constructor(appState: AppState) {
        // firebase.initializeApp(firebaseConfig);

        this.appState = appState;
    }

    async processImageAsync(buffer: ArrayBuffer, fileType: string): Promise<any> {
        if (!this.storageRef) return;

        const arr = new Uint8Array(buffer);

        // @ts-ignore
        const hash = md5(arr);

        const ref = this.storageRef.child(`images/${hash}.${fileType}`);

        const mimetype = fileType === 'png' ? Jimp.MIME_PNG : Jimp.MIME_JPEG;

        const sizes: { [key: string]: number } = {
            'large': 1600,
            'medium': 1200,
            'small': 600,
        }

        const urls:{ [key: string]: string } = {};

        try {//TODO store metadata on images so that if the image already exists, we can know image dimensions etc
            urls['full'] = repath(await ref.getDownloadURL());// already exists...

            for (let size in sizes) {
                urls[size] = repath(await this.storageRef.child(`images/${hash}_${size}.${fileType}`).getDownloadURL());
            }
        } catch (e) {
            // doesn't exist, so upload and create sizes
            const putOptions = {
                contentType: mimetype,
            };
            await ref.put(buffer, putOptions);
            const image = await Jimp.read(buffer as Buffer);

            for (let size in sizes) {
                const im = image.clone();

                //this is a little hacky in that you may have multiple identical images in there for different 'sizes'
                //but it greatly simplifies referencing the different image sizes if we don't have to worry about 'missing' ones
                if (sizes[size] < im.bitmap.width) {
                    im.resize(sizes[size], Jimp.AUTO).quality(75);
                }

                const refSize = this.storageRef.child(`images/${hash}_${size}.${fileType}`);
                await refSize.put(await im.getBufferAsync(mimetype), putOptions);
                urls[size] = repath(await refSize.getDownloadURL());
            }
        }

        return urls;
    }

    processImage(dataUrl: string | ArrayBuffer | null, fileType: string): Promise<any> {
        if (!this.storageRef) {
            this.storageRef = firebase.storage().ref();
        }
        if (dataUrl instanceof ArrayBuffer) {
            // const buffer = new Uint8Array(dataUrl);
            const buffer = dataUrl as Buffer;
            return this.processImageAsync(buffer, fileType);
        }
        throw new Error("DATA URL must be a buffer")
    }


}
