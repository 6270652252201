import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createRootStore} from "./AppState";
import {onPatches, undoMiddleware} from "mobx-keystone";
import DataManager, {IDataManager} from "./data/DataManager";
import {EditableToolbarState} from "./editables/EditableToolbarState";
import ImageHandler from "./data/ImageHandler";
import DataManagerStatic from "./data/DataManagerStatic";
import AppPerf from "./perf/AppPerf";

const rootStore = createRootStore();

const undoManager = undoMiddleware(rootStore.appState);

const dataManager:IDataManager = new DataManager(rootStore.appState);
// const dataManager:IDataManager = new DataManagerStatic(rootStore.appState);

rootStore.appState.imageHandler = new ImageHandler(rootStore.appState);

document.addEventListener('keyup', (e) => {
    //hide toolbar if click is not on a button under EditorToolbar (is there a cleaner way without referencing DOM?)
    if (e.ctrlKey && e.key === 'i') {
        rootStore.appState.editableMode = !rootStore.appState.editableMode;
        EditableToolbarState.getInstance().setEditableMode(rootStore.appState.editableMode);
    }
});

ReactDOM.render(
    <React.StrictMode>
        <App userState={rootStore.userState} appState={rootStore.appState} undoManager={undoManager} dataManager={dataManager}/>
        {/*<AppPerf userState={rootStore.userState} appState={rootStore.appState} undoManager={undoManager} dataManager={dataManager}/>*/}
    </React.StrictMode>,
    document.getElementById('root')
);

// const disposer = onPatches(rootStore, (patches, inversePatches) => {
//     patches.forEach((patch) => {
//         console.log(patch.op, patch.path.join('.'), patch);
//     });
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
