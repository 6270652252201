import React, {MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import MetricsVis from "./metrics/MetricsVis";
import MetricsIcon from './metrics/MetricsIcon';
import {AppState, ContentBlockModel, UserState} from "../AppState";
import EditableText from "../editables/EditableText";
import SideNav from "./SideNav";
import Repeater from "../editables/Repeater";
import ContentSection from "./contentBlocks/ContentSection";
import Footer from "./Footer";

@observer
export default class Measure extends React.Component<{ appState: AppState, userState: UserState }, {}> {
    render() {
        const {appState, userState} = this.props;
        return (
            <div className="Measure main-page-parent">
                <div className="main-page">
                    <SideNav
                        appState={appState}
                        content={appState.measureContent}
                    />
                    <Repeater list={appState.measureContent}
                              create={() => new ContentBlockModel({})}
                              eachRender={(contentBlockModel, index) =>
                                  <ContentSection
                                      appState={appState}
                                      userState={userState}
                                      key={`measure_contentSection_${contentBlockModel.uid}`}
                                      contentBlock={contentBlockModel}/>
                              }/>
                    <Footer appState={appState}/>
                </div>
            </div>
        );
    }
}
