import React from 'react';
import {observer} from "mobx-react";
import {AppState} from "../AppState";

@observer
export default class Footer extends React.Component<{ appState: AppState }, {}> {
    render() {
        const {appState} = this.props;
        return (
            <div className={`Footer${appState.editableMode ? ' editable' : ''}`}>
                © Density Atlas 2020. Sasaki Associates Inc.
            </div>
        );
    }
}
