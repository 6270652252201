import React, {Fragment, MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import {EditableToolbarState} from "./EditableToolbarState";

type OptionValue = any;

type Props<T extends OptionValue> = {
    create: () => T,
    list: T[],
    eachRender: (value: T, index: number) => JSX.Element;
};

@observer
export class ListableDiv extends React.Component<{id?: string, className?: string, onMouseEnter?:MouseEventHandler,onMouseLeave?:MouseEventHandler}, {}> {
    render() {
        const {onMouseEnter, onMouseLeave, children, id, className} = this.props;
        //Note: any JSXElement can be used as the child of a repeater - but it must implement onMouseEnter and onMouseLeave
        // to support the mouse-overs. This class provides a convenient wrapper for that functionality
        return (
            <div id={id} className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {children}
            </div>
        );
    }
}

const Repeater = function Repeater<T extends OptionValue>(props: Props<T>) {
    const {create, list, eachRender} = props;

    let editableToolbarState = EditableToolbarState.getInstance();

    function watch(item: T, element: JSX.Element) {
        return React.cloneElement(
            element,
            {
                onMouseEnter: (e: any) => {
                    if (!editableToolbarState.editableMode) return;
                    // console.log('ON HOVER ', e);
                    if (element.props.onMouseEnter) {//elements may use onMouseEnter for other reasons, so we should support that
                        element.props.onMouseEnter(e);
                    }
                    if (e.target && e.target.getBoundingClientRect) {
                        editableToolbarState.showToolbar(e.currentTarget, create, list, item);
                    }
                    //
                },
                onMouseLeave: (e: any) => {
                    if (!editableToolbarState.editableMode) return;

                    // console.log('ON LEAVE ', e);
                    if (element.props.onMouseLeave) {//elements may use onMouseLeave for other reasons, so we should support that
                        element.props.onMouseLeave(e);
                    }
                    // EditableToolbarState.getInstance().hideToolbar();//TEMP disabling so we can mouse-over the toolbar without it disappearing
                }
            }
        );
    }

    const eachItem = (item: T, element: JSX.Element) => {
        if (!editableToolbarState.editableMode) {
            return element;
        }
        return watch(item, element);
    };

    if (!list) {
        return null;
    }

    if (list.length === 0 && editableToolbarState.editableMode) {
        return (<button onClick={() => {
            editableToolbarState.insert(list, create());
        }
        }>+</button>)
    }

    return (
        <Fragment>
            {list.map((item, i) => {
                return eachItem(item, eachRender(item, i));
            })}
            {editableToolbarState.editableMode &&
            <button onClick={() => {
                editableToolbarState.insert(list, create());
            }
            }>+</button>}
        </Fragment>
    );
};

export default observer(Repeater);

