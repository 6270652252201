import React, {MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import {AppState, ContentBlockModel, UserState} from "../../AppState";
import ContentBlock from "./ContentBlock";
import ListContentBlock from "./ListContentBlock";
import ImageBlock from "./ImageBlock";
import SimpleSelect from "../core/SimpleSelect";
import DiagramBlock from "./DiagramBlock";
import {ListableDiv} from "../../editables/Repeater";

@observer
export default class ContentSection extends React.Component<{ appState: AppState, userState: UserState, contentBlock: ContentBlockModel, onMouseEnter?: MouseEventHandler, onMouseLeave?: MouseEventHandler }, {}> {

    renderContentByType(contentBlock: ContentBlockModel, appState: AppState, userState: UserState) {
        if (contentBlock.type === 'ContentBlock') {
            return (
                <ContentBlock
                    imageFirst={false}
                    appState={appState}
                    contentBlock={contentBlock}
                />
            )
        }
        if (contentBlock.type === 'ImageFirst') {
            return (
                <ContentBlock
                    imageFirst={true}
                    appState={appState}
                    contentBlock={contentBlock}
                />
            )
        }
        if (contentBlock.type === 'ListContentBlock') {
            return (
                <ListContentBlock
                    appState={appState}
                    contentBlock={contentBlock}
                />
            )
        }
        if (contentBlock.type === 'ImageBlock') {
            return (
                <ImageBlock
                    appState={appState}
                    contentBlock={contentBlock}
                />
            )
        }
        if (contentBlock.type === 'DiagramBlock') {
            return (
                <DiagramBlock
                    appState={appState}
                    userState={userState}
                    contentBlock={contentBlock}
                />
            )
        }
    }

    render() {
        const {contentBlock, appState, userState} = this.props;
        return (
            <ListableDiv className={"ContentSection"}>
                {appState.editableMode && <div className={'container'}>
                    <SimpleSelect
                        value={contentBlock.type}
                        setter={(selectedVal) => {
                            contentBlock.type = selectedVal;
                        }}
                        options={[
                            {value: 'ContentBlock', label: 'Default'},
                            {value: 'ImageFirst', label: 'Image First'},
                            {value: 'ListContentBlock', label: 'List with images'},
                            {value: 'ImageBlock', label: 'Images'},
                            {value: 'DiagramBlock', label: '3D Diagram'},
                        ]}
                    />
                </div>}
                {this.renderContentByType(contentBlock, appState, userState)}
            </ListableDiv>
        );

    }
}
