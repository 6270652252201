import {action, computed, IObservableArray, observable} from "mobx";
import {Model, model, modelAction, prop} from "mobx-keystone";

interface Rect {
    x: number,
    y: number,
    width: number,
    height: number,
}

@model("rfe/EditableToolbarState")
export class EditableToolbarState extends Model({
    editableMode: prop<boolean>(false),
    visible: prop<boolean>(false, {setterAction: true}),
    toolbar: prop<Rect>(() => {
        return {x: 0, y: 0, width: 200, height: 40}
    }, {setterAction: true}),
    highlightBox: prop<Rect>(() => {
        return {x: 0, y: 0, width: 0, height: 0}
    }, {setterAction: true}),
}) {

    constructor(data: any) {
        super(data);
        EditableToolbarState.sInstance = this;
        this.activeList = [];
        if (typeof document === 'undefined') return;

        document.addEventListener('scroll', () => {
            this.hideToolbar();
        });
        document.addEventListener('click', (e) => {
            //hide toolbar if click is not on a button under EditorToolbar (is there a cleaner way without referencing DOM?)
            const elem = e && e.target && e.target as HTMLInputElement;
            if (elem && elem.parentElement && elem.parentElement.classList.contains('EditorToolbar')) return;
            this.hideToolbar();
        });
    }
    @observable
    private activeElement?: Element;

    @observable
    activeList: any[];

    private activeCreate: any;//function to return new element

    @observable
    private activeItem: any;

    private static sInstance: EditableToolbarState;

    static getInstance(): EditableToolbarState {
        return EditableToolbarState.sInstance;
    }

    @modelAction
    setEditableMode(editableMode:boolean) {
        this.editableMode = editableMode;
        if (!editableMode) {
            this.visible = false;
        }
    }

    @modelAction
    updateHighlightBox() {
        if (!this.activeElement) {
            this.highlightBox.width = 0;
            this.highlightBox.height = 0;
            return;
        }
        const rect = this.activeElement.getBoundingClientRect();
        this.highlightBox.x = rect.x;
        this.highlightBox.y = rect.y;
        this.highlightBox.width = rect.width;
        this.highlightBox.height = rect.height;
    }

    @modelAction
    showToolbar<T>(elem: Element, create: () => T, list: T[], item: T) {
        console.log('SHOW TOOLBAR');
        const rect = elem.getBoundingClientRect();
        this.toolbar.x = Math.max(this.toolbar.width / 2, rect.x + rect.width / 2);
        this.toolbar.y = rect.y;
        // this.toolbar.width = rect.width;

        this.activeCreate = create;
        this.activeList = list;
        this.activeItem = item;
        this.activeElement = elem;

        this.updateHighlightBox();

        this.visible = true;

    }

    @modelAction
    hideToolbar() {
        this.visible = false;
    }

    @modelAction
    insert(list:any[], item:any) {
        list.push(item);
        this.hideToolbar();
    }

    @modelAction
    removeElement(list:any[], item:any) {
        list.splice(list.indexOf(item), 1);
        this.hideToolbar();
    }


    @modelAction
    insertBefore() {
        if (!this.activeList || !this.activeCreate) return;
        const idx = this.activeList.indexOf(this.activeItem);
        if (idx >= 0) {
            this.activeList.splice(idx, 0, this.activeCreate())
        }
        this.hideToolbar();
    }

    @modelAction
    insertAfter() {
        if (!this.activeList || !this.activeCreate) return;
        const idx = this.activeList.indexOf(this.activeItem);
        if (idx >= 0) {
            this.activeList.splice(idx + 1, 0, this.activeCreate())
        }
        this.hideToolbar();
    }


    @modelAction
    moveUp() {
        if (!this.activeList || !this.activeCreate) return;
        const idx = this.activeList.indexOf(this.activeItem);
        const newPos = Math.max(0, idx - 1);

        this.activeList.splice(idx, 1);
        this.activeList.splice(newPos, 0, this.activeItem);
        this.hideToolbar();
    }

    @modelAction
    moveDown() {
        if (!this.activeList || !this.activeCreate) return;
        const idx = this.activeList.indexOf(this.activeItem);
        const newPos = Math.min(this.activeList.length, idx + 1);

        this.activeList.splice(idx, 1);
        this.activeList.splice(newPos, 0, this.activeItem);
        this.hideToolbar();
    }

    @modelAction
    remove() {
        if (!this.activeList || !this.activeItem) return;
        const index = this.activeList.indexOf(this.activeItem);
        this.activeList.splice(index, 1);
        this.hideToolbar();
    }

    @computed
    get canMoveUp(): boolean {
        if (!this.activeList || !this.activeItem) return false;
        return this.activeList.indexOf(this.activeItem) > 0;
    }

    @computed
    get canMoveDown(): boolean {
        if (!this.activeList || !this.activeItem) return false;
        return this.activeList.indexOf(this.activeItem) < this.activeList.length - 1;
    }
}

// export class EditableToolbarState_OLD {
//     @observable visible: boolean = false;
//     @observable toolbar =
//         {
//             x: 0,
//             y: 0,
//             width: 200,
//             height: 40,
//         };
//
//     @observable highlightBox =
//         {
//             x: 0,
//             y: 0,
//             width: 0,
//             height: 0,
//         };
//
//     private activeCreate: any;
//
//
//
//     @observable
//     private activeItem: any;
//
//     @observable
//     private activeList?: any[];
//
//     private static instance: EditableToolbarState;
//
//     static getInstance(): EditableToolbarState {
//         if (!EditableToolbarState.instance) {
//             EditableToolbarState.instance = new EditableToolbarState();
//             document.addEventListener('scroll', () => {
//                 EditableToolbarState.instance.hideToolbar();
//             });
//         }
//
//         return EditableToolbarState.instance;
//     }
//
//     @action
//     updateHighlightBox() {
//         if (!this.activeElement) {
//             this.highlightBox.width = 0;
//             this.highlightBox.height = 0;
//             return;
//         }
//         const rect = this.activeElement.getBoundingClientRect();
//         this.highlightBox.x = rect.x;
//         this.highlightBox.y = rect.y;
//         this.highlightBox.width = rect.width;
//         this.highlightBox.height = rect.height;
//     }
//
//     @action
//     showToolbar<T>(elem: Element, create: () => T, list: T[], item: T) {
//         const rect = elem.getBoundingClientRect();
//         this.toolbar.x = Math.max(this.toolbar.width / 2, rect.x + rect.width / 2);
//         this.toolbar.y = rect.y;
//         // this.toolbar.width = rect.width;
//
//         this.activeCreate = create;
//         this.activeList = list;
//         this.activeItem = item;
//         this.activeElement = elem;
//
//         this.updateHighlightBox();
//
//         this.visible = true;
//
//     }
//
//     hideToolbar() {
//         this.visible = false;
//     }
//
//     @action
//     insertBefore() {
//         if (!this.activeList || !this.activeCreate) return;
//         const idx = this.activeList.indexOf(this.activeItem);
//         if (idx >= 0) {
//             this.activeList.splice(idx, 0, this.activeCreate())
//         }
//         this.hideToolbar();
//     }
//
//     @action
//     insertAfter() {
//         if (!this.activeList || !this.activeCreate) return;
//         const idx = this.activeList.indexOf(this.activeItem);
//         if (idx >= 0) {
//             this.activeList.splice(idx + 1, 0, this.activeCreate())
//         }
//         this.hideToolbar();
//     }
//
//
//     @action
//     moveUp() {
//         if (!this.activeList || !this.activeCreate) return;
//         const idx = this.activeList.indexOf(this.activeItem);
//         const newPos = Math.max(0, idx - 1);
//
//         this.activeList.splice(idx, 1);
//         this.activeList.splice(newPos, 0, this.activeItem);
//         this.hideToolbar();
//     }
//
//     @action
//     moveDown() {
//         if (!this.activeList || !this.activeCreate) return;
//         const idx = this.activeList.indexOf(this.activeItem);
//         const newPos = Math.min(this.activeList.length, idx + 1);
//
//         this.activeList.splice(idx, 1);
//         this.activeList.splice(newPos, 0, this.activeItem);
//         this.hideToolbar();
//     }
//
//     @action
//     remove() {
//         if (!this.activeList || !this.activeItem) return;
//         const index = this.activeList.indexOf(this.activeItem);
//         this.activeList.splice(index, 1);
//         this.hideToolbar();
//     }
//
//     @computed
//     get canMoveUp(): boolean {
//         if (!this.activeList || !this.activeItem) return false;
//         return this.activeList.indexOf(this.activeItem) > 0;
//     }
//
//     @computed
//     get canMoveDown(): boolean {
//         if (!this.activeList || !this.activeItem) return false;
//         return this.activeList.indexOf(this.activeItem) < this.activeList.length - 1;
//     }
// }
