import React, {MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import {AppState, CaseStudy, ContentBlockModel, Unit, UserState} from "../../AppState";
import EditableText from "../../editables/EditableText";
import MetricsIcon from "../metrics/MetricsIcon";
import MetricsVis from "../metrics/MetricsVis";
import {CardMetric} from "../explore/_Atoms";
import RadioSlider from "../RadioSlider";


@observer
export default class DiagramBlock extends React.Component<{ appState: AppState, userState: UserState, contentBlock: ContentBlockModel, onMouseEnter?: MouseEventHandler, onMouseLeave?: MouseEventHandler }, {}> {
    render() {
        const {contentBlock, onMouseEnter, onMouseLeave, appState, userState} = this.props;
        const caseStudy = appState.dummyCaseStudy;

        const metricsBlock = () =>
            <div className={"metrics"}>
                {appState.metricFormatters.map(metric => (
                    <CardMetric metric={metric} appState={appState} caseStudy={caseStudy}
                                editMode={true}/>))}
            </div>

        return (
            <div id={contentBlock.uid} className="DiagramBlock container" onMouseEnter={onMouseEnter}
                 onMouseLeave={onMouseLeave}>

                <div className="explore-content">
                    <div className="row">
                        <div className="col-sm-4">
                            <h1><EditableText editMode={appState.editableMode} lang={appState.activeLang}
                                              map={contentBlock.textValues} field="header"/></h1>
                        </div>
                        <div className="col-sm-8">
                            <h1><EditableText editMode={appState.editableMode} lang={appState.activeLang}
                                              map={contentBlock.textValues} field="measure.subtitle"
                                              defaultValue="Overview..."/></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            {!appState.mobileMode && metricsBlock()}
                            <RadioSlider<Unit> value={userState.unit} options={[Unit.Acre, Unit.Hectare]}
                                               onChange={(unit: Unit) => userState.unit = unit}/>
                            <div className={"metrics-buttons"}>
                                <button className={appState.metricsBuildingMode === 'center' ? 'selected' : ''}
                                        onClick={() => {
                                            appState.metricsBuildingMode = 'center';
                                        }}>
                                    <img src="/img/center.svg"/>
                                </button>
                                <button className={appState.metricsBuildingMode === 'corners' ? 'selected' : ''}
                                        onClick={() => {
                                            appState.metricsBuildingMode = 'corners';
                                        }}>
                                    <img src="/img/corners.svg"/>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-8 center">
                            <div className={'diagram-inline'}>
                                <MetricsVis bldgMode={appState.metricsBuildingMode} pop={caseStudy.pop}
                                            far={caseStudy.FAR}
                                            dud={caseStudy.dud} cvg={caseStudy.CVG} width={350}/>
                            </div>
                            {appState.mobileMode && metricsBlock()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
