import React, {Fragment} from 'react';
import {observer} from "mobx-react";
import {EditableToolbarState} from "./EditableToolbarState";
import {FixedSizeGrid as Grid, FixedSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

type OptionValue = any;

type Props<T extends OptionValue> = {
    create: () => T,
    list: T[],
    itemWidth?: number,
    headerHeight: number,
    itemHeight: number,
    eachRender: (value: T, index: number) => JSX.Element;
};

const RepeaterVirtualized = function RepeaterVirtualized<T extends OptionValue>(props: Props<T>) {
    const {create, list, eachRender, itemWidth, itemHeight, headerHeight} = props;

    let editableToolbarState = EditableToolbarState.getInstance();

    //TODO centralize with Repeater
    function watch(item: T, element: JSX.Element) {
        return React.cloneElement(
            element,
            {
                onMouseEnter: (e: any) => {
                    if (!editableToolbarState.editableMode) return;
                    // console.log('ON HOVER ', e);
                    if (element.props.onMouseEnter) {//elements may use onMouseEnter for other reasons, so we should support that
                        element.props.onMouseEnter(e);
                    }
                    if (e.target && e.target.getBoundingClientRect) {
                        editableToolbarState.showToolbar(e.currentTarget, create, list, item);
                    }
                    //
                },
                onMouseLeave: (e: any) => {
                    if (!editableToolbarState.editableMode) return;

                    // console.log('ON LEAVE ', e);
                    if (element.props.onMouseLeave) {//elements may use onMouseLeave for other reasons, so we should support that
                        element.props.onMouseLeave(e);
                    }
                    // EditableToolbarState.getInstance().hideToolbar();//TEMP disabling so we can mouse-over the toolbar without it disappearing
                }
            }
        );
    }

    const eachItem = (item: T, element: JSX.Element) => {
        if (!editableToolbarState.editableMode) {
            return element;
        }
        return watch(item, element);
    };

    if (!list) {
        return null;
    }
    const itemCount = list.length;

    const renderItemIfExists = (index: number, style: React.CSSProperties) => {
        if (index >= list.length) {
            return null;
        }
        const item = list[index];

        return (
            <div style={style}>
                {eachItem(item, eachRender(item, index))}
            </div>)
    };

    let virtualList: (columns: number, width: number, height: number) => JSX.Element;
    if (!itemWidth) {
        virtualList = (columns: number, width: number, height: number) => {
            return <List
                className="List"
                height={height}
                itemCount={itemCount}
                itemSize={itemHeight}
                width={width}
            >
                {(props) => {
                    const {index, style} = props;
                    return renderItemIfExists(index, style);
                }}
            </List>
        }
    } else {
        virtualList = (columns: number, width: number, height: number) => {
            // width = Math.max(width, itemWidth);//if width gets less than itemWidth, Grid expands infinitely
            const itemWidth2 = Math.min(itemWidth, width);
            return <Grid
                className="Grid"
                height={height}
                rowCount={Math.ceil(itemCount / columns)}
                columnCount={columns}
                columnWidth={itemWidth2}
                rowHeight={itemHeight}
                width={width}
            >
                {(props) => {
                    const {columnIndex, rowIndex, style} = props;
                    const index = rowIndex * columns + columnIndex;
                    return renderItemIfExists(index, style);
                }}
            </Grid>
        }
    }

    return (
        <div className={'filler'}>
            <AutoSizer>
                {({height, width}) => {
                    let columns = 1;
                    if (itemWidth) {
                        columns = Math.max(1, Math.floor(width / itemWidth));
                    }

                    console.log('AutoSizer', width, height);
                    return virtualList(columns, width, height - headerHeight);
                }}
            </AutoSizer>
        </div>
    );

    //TODO implement "ADD" button as extra 'item' in a new row / grid cell

    // if (list.length === 0 && editableToolbarState.editableMode) {
    //     return (<button onClick={() => {
    //         editableToolbarState.insert(list, create());
    //     }
    //     }>+</button>)
    // }
    //
    // return (
    //     <Fragment>
    //         {list.map((item, i) => {
    //             return eachItem(item, eachRender(item, i));
    //         })}
    //         {editableToolbarState.editableMode &&
    //         <button onClick={() => {
    //             editableToolbarState.insert(list, create());
    //         }
    //         }>+</button>}
    //     </Fragment>
    // );
};

export default observer(RepeaterVirtualized);

