import React, {createRef, FunctionComponent, useRef, useState} from 'react';
import {observer} from "mobx-react";

type EditableSpanProps = {
    editMode: boolean;
    getter: () => string;
    setter: (val:string) => void;
    defaultValue?: string,
}

const EditableSpan: FunctionComponent<EditableSpanProps> = ({editMode, getter, setter, defaultValue}) => {
    const spanRef = useRef<HTMLSpanElement>(null);

    if (!editMode) {
        return <span>{getter()}</span>
    }
    if (defaultValue && !getter()) {
        //Note disabling this for now...
        //this solves the issue that currently the default values are being written even if the user wants it to be blank...
        // setter(defaultValue || '?');
    }

    return (<span onKeyPress={(evt)=> {
        if (evt.key === 'Enter') {//spans don't work with multiple lines, enter should 'complete' the edit
            evt.preventDefault();
            if (spanRef.current) spanRef.current.blur();
        }
    }} contentEditable={true} ref={spanRef}  suppressContentEditableWarning={true} onBlur={event => {
        const node = event.currentTarget as Node;
        if (node) {
            let editedValue = String(node.textContent);
            setter(editedValue);
            // console.log('Change happens: ', node.textContent)
        }
    }}>
        {getter()}
    </span>);
};

export default observer(EditableSpan);
