import React, {MouseEventHandler} from 'react';
import {motion} from "framer-motion";
import {observer} from "mobx-react";
import {AppState, UserState, CaseStudy} from "../../AppState";
import EditableText from "../../editables/EditableText";
import MetricDisplay from "../core/MetricDisplay";
import {CardTag, CheckBox, ExpandButton} from "./_Atoms";
import {NavLink} from "react-router-dom";

type props = {
    userState: UserState,
    appState: AppState,
    editMode: boolean,
    lang: string,
    caseStudy: CaseStudy,
    onMouseEnter?: MouseEventHandler,
    onMouseLeave?: MouseEventHandler
}

export class HeaderRow extends React.Component<{ appState: AppState, userState: UserState }, {}> {

    render() {
        const {appState, userState} = this.props;
        const {filter} = userState;
        const text = (defaultValue: string) => {
            return <EditableText map={appState.terms} editMode={appState.editableMode} lang={appState.activeLang}
                                 defaultValue={defaultValue} field={defaultValue.toLowerCase().split(' ').join('-')}/>
        }

        return (<div className="Row header-row">
            <div/>
            <div>{text('Name')}</div>
            {!appState.mobileMode && <>
                <div>{text('City')}</div>
                <div>{text('Country')}</div>
                <div>{text('Scale')}</div>
            </>}
            <div className="gap"/>
            {appState.mobileMode && appState.metricFormatters.map(metric =>
                (filter.scale === metric.subtext) &&
                <div key={metric.subtext}
                     className={(filter.scale === metric.subtext) ? 'active-sort-by' : 'sort-by'}
                     onClick={() => {
                         filter.scale = metric.subtext
                     }}
                >{metric.subtext}</div>)
            }
            {!appState.mobileMode && appState.metricFormatters.map(metric => (
                <div key={metric.subtext}
                     className={(filter.scale === metric.subtext) ? 'active-sort-by' : 'sort-by'}
                     onClick={() => {
                         filter.scale = metric.subtext
                     }}
                >{metric.subtext}</div>
            ))
            }
            <div/>

        </div>);
    }
}

@observer
export default class Row extends React.Component<props, {}> {

    render() {
        const {appState, userState, editMode, lang, caseStudy, onMouseEnter, onMouseLeave} = this.props;
        const {textValues, uid} = caseStudy;
        const {filter} = userState;

        if (userState.modalId === caseStudy.uid) {//don't render this layoutId twice as frame motion gets confused
            // console.log('userState.modalId MATCH ', userState.modalId)
            return <div className="Row"/>
        }

        return (
            <motion.div initial={false} layoutId={uid} className="Row" onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}>
                <ExpandButton size={16} color="#999999" appState={appState} caseStudy={caseStudy}/>
                <motion.div layoutId={`${uid}_name_wrapper`} layout>
                    <NavLink to={`/explore/details/${encodeURIComponent(caseStudy.uid)}`}>
                        <motion.div layoutId={`${uid}_name`} className="h3 anim-text">
                            <EditableText editMode={editMode} lang={lang} map={textValues} field="name"
                                          defaultValue="Enter Case Study Name"/>
                        </motion.div>
                    </NavLink>
                </motion.div>

                {!appState.mobileMode && <>
                <div><CardTag editMode={editMode} lang={lang} caseStudy={caseStudy} appState={appState}
                              field={'location'} borderless={true}/></div>
                <div><CardTag editMode={editMode} lang={lang} caseStudy={caseStudy} appState={appState}
                              field={'country'}/></div>
                <div><CardTag editMode={editMode} lang={lang} caseStudy={caseStudy} appState={appState}
                              field={'scale'}/></div>
                </>}

                <div className="gap"/>
                {appState.mobileMode && appState.metricFormatters.map(metric => (filter.scale === metric.subtext) &&
                    <motion.div key={`${uid}_${metric.className}`} layoutId={`${uid}_${metric.className}`}
                                className={`Row__metric ${metric.className}`}>
                        <MetricDisplay editMode={editMode} metric={metric} caseStudy={caseStudy}/>
                    </motion.div>
                )}
                {!appState.mobileMode && appState.metricFormatters.map(metric => (
                    <motion.div key={`${uid}_${metric.className}`} layoutId={`${uid}_${metric.className}`}
                                className={`Row__metric ${metric.className}`}>
                        <MetricDisplay editMode={editMode} metric={metric} caseStudy={caseStudy}/>
                    </motion.div>
                ))}

                <div className={`checkbox`}>
                    <CheckBox appState={appState} caseStudy={caseStudy}/>
                </div>
            </motion.div>
        );
    }
}
