import React, {MouseEventHandler, useEffect} from 'react';
import {motion} from "framer-motion";
import {observer} from "mobx-react";
import {AppState, UserState, CaseStudy, ContentBlockModel, ImageModel, DocData} from "../../AppState";
import EditableText from "../../editables/EditableText";
import EditableImage from "../../editables/EditableImage";
import LinkedLookup from "../../editables/LinkedLookup";
import {CardMetric, CardTags, CheckBox, ExpandButton} from "./_Atoms";
import MetricsVis from "../metrics/MetricsVis";
import EditableRichText from "../../editables/EditableRichText";
import FigureGround from "./FigureGround";
import Repeater from "../../editables/Repeater";
import ContentSection from "../contentBlocks/ContentSection";

@observer
class DocDataBlocks extends React.Component<{ appState: AppState, userState: UserState, docData: DocData }, {}> {
    render() {
        const {docData, appState, userState} = this.props;
        return (
            <Repeater list={docData.content}
                      create={() => new ContentBlockModel({})}
                      eachRender={(contentBlockModel, index) =>
                          <ContentSection
                              appState={appState}
                              userState={userState}
                              key={`docData_contentSection_${contentBlockModel.uid}`}
                              contentBlock={contentBlockModel}/>
                      }/>
        );
    }
}


@observer
export default class Details extends React.Component<{ appState: AppState, userState: UserState, editMode: boolean, lang: string, caseStudy?: CaseStudy, close: () => void, onMouseEnter?: MouseEventHandler, onMouseLeave?: MouseEventHandler }, {}> {

    componentDidMount() {
        const {caseStudy} = this.props;
        console.log("Component MOUNT", caseStudy)
    }

    render() {
        const {appState, userState, editMode, lang, caseStudy, onMouseEnter, onMouseLeave, close} = this.props;
        if (!caseStudy) {
            return null;
        }

        setTimeout(() => {
            caseStudy.docRef.ensureLoaded();
        }, 1000);//allow a second for animation before loading

        const {textValues, lookups, uid} = caseStudy;
        const heroImage = caseStudy.getHeroImage();
        return (
            <motion.div layoutId={uid} className="Details explore-content" onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}>
                <motion.div layoutId={`${uid}_hero`}>
                    <EditableImage className={'banner'} urlPrefix={appState.imageUrlPrefix} lang={appState.activeLang} displaySize={'large'}
                                   imageHandler={appState.imageHandler}
                                   editMode={editMode}
                                   imageState={heroImage}/>
                </motion.div>

                <div className="content">
                    {editMode && <div>
                        <label>
                            <input type="checkbox" checked={caseStudy.draftMode}
                                   onClick={() => caseStudy.draftMode = !caseStudy.draftMode}/>Draft
                        </label>
                    </div>}
                    <motion.div layoutId={`${uid}_name_wrapper`} layout>
                        <motion.h3 className={'anim-text'} layoutId={`${uid}_name`} layout>
                            <EditableText editMode={editMode} lang={lang}
                                          map={textValues}
                                          field="name"
                                          defaultValue="Enter Case Study Name"/>
                        </motion.h3>
                    </motion.div>
                    <motion.div layoutId={`${uid}_tag_location`} className="location">
                        <motion.div layout>
                            <LinkedLookup editMode={editMode} list={appState.linkedObjects} lang={lang}
                                          lookups={lookups}
                                          field="location"/>
                        </motion.div>
                    </motion.div>

                    <CardTags lang={lang} editMode={editMode} appState={appState} caseStudy={caseStudy}/>

                    <div className="metrics">
                        {appState.metricFormatters.map((metric) => (
                            <CardMetric key={metric.className} metric={metric} appState={appState} caseStudy={caseStudy}
                                        editMode={editMode}/>
                        ))}
                    </div>
                    <div className={"fg-metrics-container"}>
                        <div className={"graphic"}>
                            <MetricsVis bldgMode={'center'}
                                        width={250}
                                        cvg={caseStudy.CVG}
                                        dud={caseStudy.dud}
                                        far={caseStudy.FAR}
                                        pop={caseStudy.pop}/>

                        </div>
                        <FigureGround height={'414px'} appState={appState} spatialData={caseStudy.spatialData}/>
                    </div>
                    <div className="details-gmaps-link">
                        <a href={`https://www.google.com/maps/@${caseStudy.position.lat},${caseStudy.position.lng},739a,35y,0.37h/data=!3m1!1e3`} target="_blank">Open in Google Maps</a>
                    </div>

                    <EditableRichText editMode={editMode} lang={lang} map={caseStudy.textValues}
                                      field="content" defaultValue="Case study content"/>
                </div>
                {caseStudy.docRef.isLoading &&
                <div>Loading additional data...</div>
                }
                {caseStudy.docRef.isLoaded && caseStudy.docRef.data &&
                    <DocDataBlocks userState={userState} appState={appState} docData={caseStudy.docRef.data}/>
                }

                <div className={`checkbox`}>
                    <CheckBox appState={appState} caseStudy={caseStudy}/>
                </div>
                <ExpandButton close={close} collapseMode={true} size={24} color={"#ffffff"} appState={appState}
                              caseStudy={caseStudy}/>
            </ motion.div>
        );
    }
}
