import {Model, model, modelAction, prop, prop_mapObject} from "mobx-keystone";
import {computed, observable} from "mobx";

@model("rfe/ImageState")
export class ImageState extends Model({
    urls: prop<TextMap>(() => new TextMap({}), {}),
    textValues: prop<LangMap>(() => new LangMap({}), {}),
}) {

}

@model("rfe/LookupLinks")
export class LookupLinks extends Model({
    links: prop_mapObject(() => new Map<string, string>()),
}) {

    @observable
    getKey(link: string): string | undefined {
        return this.links.get(link);
    }

    @observable
    get(list: LookupList, lang: string, link: string): string {
        const key = this.getKey(link);
        if (!key) {
            return ''
        }
        return list.getLink(lang, link, key);
    }

    @modelAction
    set(link: string, key: string): void {
        this.links.set(link, key)
    }
}

export interface IOption {
    value: string,
    label: string
}

@model("rfe/LookupList")
export class LookupList extends Model({
    links: prop_mapObject(() => new Map<string, LangMap>()),
}) {
    @observable
    getOptions(lang: string, link: string): Array<IOption> {
        let langMap = this.links.get(link);
        const options: Array<IOption> = [];
        if (!langMap) {
            return options;
        }
        for (const key of Array.from(langMap.map.keys())) {
            options.push({value: key, label: langMap.get(lang, key)})
        }
        return options;
    }

    @observable
    getLink(lang: string, link: string, key: string): string {
        let langMap = this.links.get(link);
        if (!langMap) return '';
        return langMap.get(lang, key);
    }

    @modelAction
    setLink(lang: string, link: string, key: string, value: string) {
        let langMap = this.links.get(link);
        if (!langMap) {
            langMap = new LangMap({});
            this.links.set(link, langMap)
        }
        langMap.set(lang, key, value);
    }

    // @modelAction
    // addOption(lang:string, link:string, option:string) {
    //     let langMap = this.links.get(link);
    //     const options: Array<IOption> = [];
    //     if (!langMap) {
    //         langMap = new LangMap({});
    //     }
    //     langMap.set(lang, option);
    // }
}

@model("rfe/TextMap")
export class TextMap extends Model({
    map: prop_mapObject(() => new Map<string, string>()),
}) {
    @observable
    get(key: string): string {
        return this.map.get(key) || ''
    }

    @modelAction
    set(key: string, value: string) {
        this.map.set(key, value);
    }

    @modelAction
    delete(key: string) {
        this.map.delete(key);
    }

    @modelAction
    setMultiple(kvps: any) {
        Object.keys(kvps).forEach((k) => {
            this.map.set(k, kvps[k]);
        });
    }
}

@model("rfe/LangMap")
export class LangMap extends Model({
    map: prop_mapObject(() => new Map<string, TextMap>()),
}) {
    @observable
    get(lang: string, key: string): string {
        let keyMap = this.map.get(key);
        if (!keyMap) return '';
        // if (typeof keyMap === 'string') { return keyMap} //backwards compatibility... should remove
        return keyMap.get(lang) || '';
    }

    @modelAction
    delete(lang: string, key: string) {
        let keyMap = this.map.get(key);
        if (!keyMap) return;
        keyMap.delete(lang);
    }

    @modelAction
    set(lang: string, key: string, value: string) {
        let keyMap = this.map.get(key);
        if (!keyMap || typeof keyMap === 'string') {
            keyMap = new TextMap({});
            this.map.set(key, keyMap);
        }
        keyMap.set(lang, value);
    }

    @modelAction
    setFromObject(obj: any) {
        return;//TODO replace terms with direct content
        const explode = (pathArr: string[], obj: any) => {
            Object.keys(obj).forEach((k: string) => {
                let p = [...pathArr, k];
                if (typeof obj[k] === 'string') {
                    console.log(`${p.join('.')} ${obj[k]}`)
                    this.map.set(p.join('.'), obj[k]);
                } else {
                    explode(p, obj[k]);
                }
            });
        };
        explode([], obj);
    }
}
