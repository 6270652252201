import React, {MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import Sketch from "react-p5";
import p5Types from "p5"; //Import this for typechecking and intellisense

type Props = {
    bldgMode: string,
    pop: number, far: number, dud: number, cvg: number, width: number,
};
@observer
export default class MetricsVis extends React.Component<Props, {}> {
    private rerender: () => void;

    constructor(props: Props) {
        super(props);
        this.rerender = () => {
        };
    }

    render() {
        const {pop, far, dud, cvg, bldgMode, width} = this.props;

        //TODO re-render this component when any of these values changes

        const cvgFrac = cvg / 100;

        let siteLen = 300;
//let bldgMode = 'corners'

        const useRounding = true;

        let popColor = "#330B61";
        let dudColor = "#61D39C";
        let farColorLight = "#FDE8D0";
        let farColorDark = "#F77D00";
        let cvgColor = "#E33039";
        let siteColor = "#fbc12d";

        let bgMode2 = bldgMode;

        const height = width * 1.65;
        const scale = width / 420;

        this.rerender();

        // const {sectionState, onMouseEnter, onMouseLeave} = this.props;
        const setup = (p5: p5Types, canvasParentRef: Element) => {
            p5.createCanvas(width, height, p5.WEBGL).parent(canvasParentRef);
            p5.noLoop();
            this.rerender = () => {
                setTimeout(() => {
                    //weird issue with timing (values are not updated unless we use a delay)
                    p5.redraw();
                }, 10);
            };
        };

        const drawSite = (p5: p5Types, siteLen: number) => {
            p5.strokeWeight(7);
            p5.stroke(siteColor);
            p5.noStroke();
            p5.fill(235);
            // p5.noFill();
            p5.rectMode(p5.CENTER);
            p5.rect(0, 0, siteLen, siteLen);
        }

        const drawRectIn = (p5: p5Types, x: number, y: number, w: number, h: number, minX: number, minY: number, maxX: number, maxY: number) => {
            //something funky with the coords here...
            //disabling for now
            p5.rect(x, y, w, h);
            return;
            const right = Math.min(x + w, maxX);
            const bottom = Math.min(y + h, maxY);
            const left = Math.max(x, minX);
            const top = Math.min(y, minY);

            if (x < minX) {
                return;
                p5.fill('#ff88ff');
                w = right - minX;
                // x = minX;
            }
            if (x + w > maxX) {
                // return;
                // p5.fill('#ff00ff');

            }
            if (y < minY) {
                return;
                p5.fill('#fcab57');
                h = bottom - minY
                // h = (minY - y);
                // y = minY;
            }
            if (y + h > maxY) {
                // p5.fill('#ac7a6a');

            }
            // p5.rect(left, top, right - x, bottom - y);
            p5.rect(x, y, w, h);

        }

        const drawBldg = (p5: p5Types, popu: number, dud: number, far: number, cvg: number, centerX: number, centerY: number, bldgLenX: number, bldgLenY: number) => {
            if (cvg === 0) return;

            p5.push();
            p5.translate(centerX, centerY, 0);

            let duON = true
            let popdudScale = 1

            let numFloors = far / cvg
            //console.log("floors", numFloors);
            //console.log("cvg", cvg);
            let dudRound = p5.pow(dud / popdudScale / numFloors, 0.5);
            if (useRounding) {
                dudRound = p5.round(dudRound);
            }
            let dudSpacing = bldgLenX / dudRound;
            let popRound = p5.pow(popu / popdudScale / numFloors, 0.5);
            if (useRounding) {
                popRound = p5.round(popRound);
            }
            let popSpacing = bldgLenX / popRound;
            let floorHeight = 25;

            let popSize = 3;

            p5.rectMode(p5.CENTER);

            let cvgDepth = 8;
            p5.translate(0, 0, cvgDepth / 2);
            p5.noStroke();
            p5.fill(cvgColor);
            p5.box(bldgLenX, bldgLenY, cvgDepth);

            p5.translate(0, 0, cvgDepth / 2 + 1);

            for (let i = 2; i <= p5.round(numFloors); i++) {
                p5.push();
                p5.translate(0, 0, floorHeight * (i - 1) - 1);
                p5.strokeWeight(7);
                p5.stroke(farColorLight);
                p5.noFill();
                p5.rect(0, 0, bldgLenX, bldgLenY);
                p5.pop();
            }

            for (let i = 1; i <= p5.round(numFloors); i++) {
                p5.strokeWeight(4);
                p5.stroke(farColorDark);
                p5.fill(farColorLight);
                p5.rect(0, 0, bldgLenX, bldgLenY);

                if (duON) {
                    const inset = 20;
                    for (let k = 0; k < dudRound; k++) {
                        for (let m = 0; m < dudRound; m++) {
                            p5.fill(dudColor);
                            p5.noStroke();
                            p5.translate(0, 0, 0.5);

                            drawRectIn(p5, bldgLenX / 2 - dudSpacing / 2 - dudSpacing * m, bldgLenY / 2 - dudSpacing / 2 - dudSpacing * k, bldgLenX / dudRound * 0.75, bldgLenY / dudRound * 0.75, -bldgLenX / 2 + inset, -bldgLenY / 2 + inset, bldgLenX / 2 - inset, bldgLenY / 2 - inset);
                            p5.translate(0, 0, -0.5);
                        }
                    }
                }

                //   for (let j = 1; j < duSquareLen; j++){
                //     strokeWeight(7);
                //     stroke(dudColor);
                //     line(bldgLen/2 - duSpacing*j, bldgLen/2, bldgLen/2 - duSpacing*j, -bldgLen/2);
                //     line(bldgLen/2, bldgLen/2 - duSpacing*j, -bldgLen/2, bldgLen/2 - duSpacing*j);
                //   }
                // }

                for (let k = 0; k < popRound; k++) {
                    for (let m = 0; m < popRound; m++) {
                        p5.fill(popColor);
                        p5.noStroke();
                        p5.push();
                        p5.translate(bldgLenX / 2 - popSpacing / 2 - popSpacing * m, bldgLenY / 2 - popSpacing / 2 - popSpacing * k, popSize / 2 + 1);
                        p5.sphere(popSize);
                        p5.pop();
                        //translate(0,0,0.5);
                        //circle(bldgLen/2 - popSpacing/2 - popSpacing*m, bldgLen/2 - popSpacing/2 - popSpacing*k, popSize);
                        //rect(bldgLen/2 - popSpacing/2 - popSpacing*m, bldgLen/2 - popSpacing/2 - popSpacing*k, popSize, popSize);
                        //translate(0,0,-0.5);
                    }
                }

                p5.translate(0, 0, floorHeight);
            }
            p5.pop();

        }

        const draw = (p5: p5Types) => {
            // p5.background(255);
            p5.clear();
            p5.scale(scale);

            p5.push();
            p5.ortho(-p5.width / 2, p5.width / 2, p5.height / 2, -p5.height / 2, 0, 1000);
            p5.angleMode(p5.DEGREES);
            p5.rotateX(-60);
            p5.rotateZ(-60);

            p5.translate(0, 0, -250);

            drawSite(p5, siteLen);

            let bldgLenX, bldgLenY;
            if (bldgMode === "center") {
                bldgLenX = p5.pow(cvgFrac * siteLen * siteLen, 0.5);
                bldgLenY = p5.pow(cvgFrac * siteLen * siteLen, 0.5);

                drawBldg(p5, pop, dud, far, cvgFrac, 0, 0, bldgLenX, bldgLenY);

            } else if (bldgMode === 'corners') {
                bldgLenX = p5.pow(cvgFrac / 4 * siteLen * siteLen, 0.5);
                bldgLenY = p5.pow(cvgFrac / 4 * siteLen * siteLen, 0.5);

                drawBldg(p5, pop / 4, dud / 4, far / 4, cvgFrac / 4, siteLen / 2 - bldgLenX / 2, siteLen / 2 - bldgLenY / 2, bldgLenX, bldgLenY);
                drawBldg(p5, pop / 4, dud / 4, far / 4, cvgFrac / 4, -(siteLen / 2 - bldgLenX / 2), siteLen / 2 - bldgLenY / 2, bldgLenX, bldgLenY);
                drawBldg(p5, pop / 4, dud / 4, far / 4, cvgFrac / 4, siteLen / 2 - bldgLenX / 2, -(siteLen / 2 - bldgLenY / 2), bldgLenX, bldgLenY);
                drawBldg(p5, pop / 4, dud / 4, far / 4, cvgFrac / 4, -(siteLen / 2 - bldgLenX / 2), -(siteLen / 2 - bldgLenY / 2), bldgLenX, bldgLenY);
            }

            p5.pop();
        };

        return <Sketch setup={setup} draw={draw}/>;
    }
}
