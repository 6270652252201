import React, {FunctionComponent, Fragment} from "react";
import {observer} from "mobx-react";
import {EditableToolbarState} from "../EditableToolbarState";

type EditorToolbarProps = {
    visible: boolean,
}

const EditorToolbar: FunctionComponent<EditorToolbarProps> = ({visible}) => {
    const ts = EditableToolbarState.getInstance();
    console.log('EditorToolbar RENDER ' + ts.visible + ' : ' + visible);
    if (!ts.visible) return null;
    const tb = ts.toolbar;
    const hb = ts.highlightBox;
    return (
        <Fragment>
            <div className="EditorToolbar" style={{
                height: tb.height,
                width: tb.width,
                left: tb.x - tb.width / 2,
                top: tb.y - tb.height
            }}>
                <button className="icon-button" disabled={!ts.canMoveUp} onClick={() => ts.moveUp()}>up</button>
                <button className="icon-button" disabled={!ts.canMoveDown} onClick={() => ts.moveDown()}>dn</button>
                <button className="icon-button" onClick={() => ts.insertBefore()}>B4</button>
                <button className="icon-button" onClick={() => ts.insertAfter()}>Aftr</button>
                <button className="icon-button" onClick={() => ts.remove()}>x</button>
            </div>
            <div className="EditorHighlightBox" style={{
                height: hb.height,
                width: hb.width,
                left: hb.x,
                top: hb.y
            }}/>
        </Fragment>
    )
};


export default observer(EditorToolbar);
