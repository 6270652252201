export default {//from Settings > General > Your Apps (Section) > Config (Radio)
    apiKey: "AIzaSyDYVWkd8QxJpEiJilqapnIJ5osCy_KNGCg",
    authDomain: "densityatlas-b3dbd.firebaseapp.com",
    databaseURL: "https://densityatlas-b3dbd.firebaseio.com",
    projectId: "densityatlas-b3dbd",
    storageBucket: "densityatlas-b3dbd.appspot.com",
    messagingSenderId: "918694587499",
    appId: "1:918694587499:web:3a6b344ec5120851dc152e",
    measurementId: "G-5N16E1Y04L"
};
