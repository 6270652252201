import React from 'react';
import {observer} from "mobx-react";
import {AppState, ContentBlockModel} from "../AppState";
import ContentSection from "./contentBlocks/ContentSection";
import Repeater from "../editables/Repeater";

@observer
export default class SideNav extends React.Component<{ appState: AppState, content: ContentBlockModel[] }, {}> {
    render() {
        const {content, appState} = this.props;
        console.log('c', content);
        return (
            <div className="SideNav">
                <nav>
                    <ul>
                        <Repeater list={content}
                                  create={() => new ContentBlockModel({})}
                                  eachRender={(link, index) =>
                                      <li className="logo-link">
                                          <a href={`#${link.uid}`}>
                                              {link.textValues.get(appState.activeLang, 'header') && link.textValues.get(appState.activeLang, 'header')}
                                          </a>
                                      </li>
                                  }/>
                    </ul>
                </nav>
            </div>
        );
    }
}
