import {observer} from "mobx-react";
import React from "react";
import {AppState, CaseStudy} from "../../AppState";
import {motion} from "framer-motion";
import LinkedLookup from "../../editables/LinkedLookup";
import MetricDisplay from "../core/MetricDisplay";
import {getRoot} from "mobx-keystone";
import {NavLink} from "react-router-dom";

const normalizeEventXY = (e: MouseEvent|TouchEvent) => {
    if (e instanceof MouseEvent) {
        return e;
    }
    return (e as TouchEvent).touches[0];
};

@observer
export class CardMetric extends React.Component<{ appState: AppState, caseStudy: CaseStudy, editMode: boolean, metric: { formatter: (d: number) => string; total: number; getter: (d: CaseStudy) => number; subtext: string; className: string; setter: (d: CaseStudy, value: number) => void } }, {}> {

    initDrag(target: EventTarget) {
        console.log(target);

        const onMouseMove = (e: MouseEvent|TouchEvent) => {
            const {caseStudy, metric} = this.props;
            const {clientX, clientY} = normalizeEventXY(e);
            const parentNode = ((target as HTMLElement).parentNode as Element);

            if (parentNode) {
                const {x, width} = parentNode.getBoundingClientRect();
                let pos = (clientX - x) / width;
                if (pos < 0) {
                    pos = 0
                } else if (pos > 1) {
                    pos = 1;
                }

                metric.setter(caseStudy, pos * metric.total);
            }
        };

        const clearEvents = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('touchmove', onMouseMove);
            window.removeEventListener('mouseup', clearEvents);
            window.removeEventListener('touchend', clearEvents);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('touchmove', onMouseMove);
        window.addEventListener('mouseup', clearEvents);
        window.addEventListener('touchend', clearEvents);
    }

    render() {
        const {metric, editMode, caseStudy} = this.props;

        return (
            <div className={`metric ${metric.className}`}>
                <motion.div layoutId={`${caseStudy.uid}_${metric.className}_text`} className="metric-value">
                    <MetricDisplay editMode={editMode} metric={metric} caseStudy={caseStudy}/>
                </motion.div>

                <motion.div layoutId={`${caseStudy.uid}_${metric.className}_bar`} className="metric-bar">
                    <div
                        className="metric-bar-value"
                        style={{width: `${100 * (metric.getter(caseStudy) > 0 ? metric.getter(caseStudy) : 0) / metric.total}%`}}
                    />

                    {editMode && (
                        <div
                            className="metric-bar-slider"
                            onMouseDown={({target}) => this.initDrag(target)}
                            onTouchStart={({target}) => this.initDrag(target)}
                            style={{left: `calc(${100 * (metric.getter(caseStudy) > 0 ? metric.getter(caseStudy) : 0) / metric.total}% - 5px)`}}
                        />
                    )}
                </motion.div>

                <div className="metric-subtext">{metric.subtext}</div>
            </div>
        )
    }
}

@observer
export class CardTag extends React.Component<{ appState: AppState, editMode: boolean, lang: string, field: string, caseStudy: CaseStudy, borderless?: boolean }, {}> {

    render() {
        const {appState, editMode, lang, caseStudy, field, borderless} = this.props;
        const {lookups, uid} = caseStudy;
        const selected = getRoot(caseStudy).userState.filter.metric === lookups.getKey(field);
        const bubbleStyles = `tag-bubble ${editMode ? 'tag-bubble--edit' : ''} ${selected ? 'tag-bubble--active' : ''}`;
        return (
            <motion.div layoutId={`${uid}_tag_${field}`}
                        className={borderless ? '' : bubbleStyles} layout>
                <motion.div layout>
                    <LinkedLookup editMode={editMode} list={appState.linkedObjects} lang={lang} lookups={lookups}
                                  field={field}/>
                </motion.div>
            </motion.div>
        )
    }
}

@observer
export class CardTags extends React.Component<{ appState: AppState, editMode: boolean, lang: string, caseStudy: CaseStudy }, {}> {
    render() {
        const {appState, editMode, lang, caseStudy} = this.props;

        return (
            <div className="tags">
                <CardTag lang={lang} editMode={editMode} appState={appState} caseStudy={caseStudy} field={'scale'}/>
                <CardTag lang={lang} editMode={editMode} appState={appState} caseStudy={caseStudy} field={'country'}/>
            </div>
        )
    }
}

@observer
export class CheckBox extends React.Component<{ appState: AppState, caseStudy: CaseStudy }, {}> {
    render() {
        const {appState, caseStudy} = this.props;

        return (
            <motion.div layoutId={`${caseStudy.uid}_checkbox-element`}
                        className={`checkbox-element ${caseStudy.selected ? 'selected' : ''}`}
                        onClick={() => appState.toggleSelectedCaseStudy(caseStudy)}
            >
                {caseStudy.selected && (<span>&#10004;</span>)}
            </motion.div>
        )
    }
}

@observer
export class ExpandButton extends React.Component<{ appState: AppState, caseStudy: CaseStudy, color: string, size: number, collapseMode?: boolean, close?: () => void }, {}> {
    render() {
        const {collapseMode, caseStudy, color, size, close} = this.props;

        //SVG from https://iconify.design/icon-sets/feather/
        //close function is a bit of a hack, but not clear how to go back to previous state with a NavLink
        //also not ideal because 'back' function doesn't always take you 'back' (e.g. if you select the case study while in full screen mode)
        //we may have to do something on userState to track this...
        return (
            <motion.div layoutId={`${caseStudy.uid}_expand-button`} className={'expand-button'}>
                {collapseMode ?
                    <button onClick={() => close && close()}>
                        <svg viewBox="0 0 24 24" width={size} height={size}>
                            <g fill="none" stroke={color} strokeWidth="2" strokeLinecap="round"
                               strokeLinejoin="round">
                                <path d="M4 14h6v6"/>
                                <path d="M20 10h-6V4"/>
                                <path d="M14 10l7-7"/>
                                <path d="M3 21l7-7"/>
                            </g>
                        </svg>
                    </button> :

                    <NavLink to={`/explore/details/${encodeURIComponent(caseStudy.uid)}`}>
                        <svg viewBox="0 0 24 24" width={size} height={size}>
                            <g fill="none" stroke={color} strokeWidth="2" strokeLinecap="round"
                               strokeLinejoin="round">
                                <path d="M15 3h6v6"/>
                                <path d="M9 21H3v-6"/>
                                <path d="M21 3l-7 7"/>
                                <path d="M3 21l7-7"/>
                            </g>
                        </svg>
                    </NavLink>}
            </motion.div>
        )
    }
}
