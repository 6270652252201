import React, {FunctionComponent, Fragment} from 'react';
import {observer} from "mobx-react";
import {AnimatePresence, AnimateSharedLayout, motion} from "framer-motion"
import {AppState, CaseStudy, ViewMode, syncQueryParams, UserState} from "../../AppState";
import Repeater from "../../editables/Repeater";
import Card from "./Card";
import Row, {HeaderRow} from "./Row";
import EditableText from "../../editables/EditableText";
import FilterPanel from './FilterPanel';
import CompareView from "./CompareView";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import About from "../About";
import Modal from "../core/Modal";
import Details from "./Details";
import RepeaterVirtualized from "../../editables/RepeaterVirtualized";
import MapView from "./MapView";
import {ExpandButton} from "./_Atoms";

type ScrollViewBaseProps = {
    appState: AppState,
    userState: UserState,
    activeLang: string,
    filteredCaseStudies: CaseStudy[],
}
type ScrollViewProps = {
    eachRender: (value: any, index: number) => JSX.Element;
    itemWidth?: number,
    itemHeight: number,
}
type HeaderRowProps = {
    headerRender?: () => JSX.Element;
    headerHeight: number;
}

const ScrollViewFC: FunctionComponent<ScrollViewBaseProps & ScrollViewProps & HeaderRowProps> = ({appState, filteredCaseStudies, eachRender, itemWidth, itemHeight,headerHeight, headerRender}) => {
    return <div className={'case-studies--card'}>
        <div className={'scroll-list explore-content'}>
            {headerRender && headerRender()}
            <RepeaterVirtualized
                itemHeight={itemHeight}
                headerHeight={headerHeight}
                itemWidth={itemWidth}
                list={filteredCaseStudies}
                create={() => new CaseStudy({})}
                eachRender={eachRender}
            />
        </div>
    </div>
}
const ScrollView = observer(ScrollViewFC);

const CardView: FunctionComponent<ScrollViewBaseProps> = ({appState, userState, activeLang, filteredCaseStudies}) => {
    return <ScrollView itemHeight={670} itemWidth={420} headerHeight={0} {...{appState, userState, activeLang, filteredCaseStudies}}
                       eachRender={caseStudy =>
                           <Card appState={appState} userState={userState}
                                 editMode={appState.editableMode}
                                 lang={activeLang} key={caseStudy.uid} caseStudy={caseStudy}/>
                       }/>
}
const RowView: FunctionComponent<ScrollViewBaseProps> = ({appState, userState, activeLang, filteredCaseStudies}) => {
    return <ScrollView itemHeight={64} {...{appState, userState, activeLang, filteredCaseStudies}}
                       headerHeight={32}
                       headerRender={() => <HeaderRow userState={userState} appState={appState}/>}
                       eachRender={caseStudy =>
                           <Row appState={appState} userState={userState}
                                editMode={appState.editableMode}
                                lang={activeLang} key={caseStudy.uid} caseStudy={caseStudy}/>
                       }/>
}

const ActiveView: FunctionComponent<ScrollViewBaseProps & { selectedCaseStudies: CaseStudy[] }> = ({appState, userState, activeLang, filteredCaseStudies, selectedCaseStudies}) => {
    // if (userState.modalId) return null;//Note: disabling renders here because renders below modal are triggering even though nothing changes...
    console.log('RENDER ActiveView =========================================', userState.viewMode)
    if (userState.viewMode === ViewMode.Compare && selectedCaseStudies.length > 0) {
        return <CompareView appState={appState} list={selectedCaseStudies}
                            editMode={appState.editableMode}
                            lang={activeLang}/>
    } else if (userState.viewMode === ViewMode.Row) {
        return <RowView appState={appState} userState={userState} activeLang={activeLang}
                        filteredCaseStudies={filteredCaseStudies}/>
    } else if (userState.viewMode === ViewMode.Map) {
        return <MapView appState={appState} userState={userState} activeLang={activeLang}
                        filteredCaseStudies={filteredCaseStudies}/>
    }
    //else if (userState.viewMode === ViewMode.Card) {
    return <CardView appState={appState} userState={userState} activeLang={activeLang}
                     filteredCaseStudies={filteredCaseStudies}/>
}


@observer
export default class Explore extends React.Component<{ appState: AppState, userState: UserState, }, { filterPanelIsOpen: boolean }> {

    constructor(props: any) {
        super(props);
        const {appState} = this.props;

        this.state = {
            filterPanelIsOpen: !appState.mobileMode,
        };
    }

    render() {
        const {filterPanelIsOpen} = this.state;
        const {appState, userState} = this.props;
        const {activeLang, caseStudies, filteredCaseStudies, selectedCaseStudies, textValues} = appState;


        return (
            <div className="Explore">
                <button className="toggle"
                        onClick={() => this.setState({filterPanelIsOpen: !filterPanelIsOpen})}>
                    {filterPanelIsOpen ? '-' : '+'}
                </button>

                <div className={`Explore__layout ${filterPanelIsOpen ? 'Explore__layout--open' : ''}`}>
                    {filterPanelIsOpen && <FilterPanel appState={appState} userState={userState}
                                 compareMode={userState.viewMode === ViewMode.Compare}/>}
                    <div className={`case-studies`}>
                        <AnimatePresence>
                            <AnimateSharedLayout type="switch">
                                <Fragment>
                                    <ActiveView appState={appState} userState={userState} activeLang={activeLang}
                                                filteredCaseStudies={filteredCaseStudies}
                                                selectedCaseStudies={selectedCaseStudies}/>
                                    {userState.modalId &&
                                    <Modal>
                                        <Details close={() => userState.closeModal()} appState={appState}
                                                 userState={userState}
                                                 editMode={appState.editableMode}
                                                 lang={activeLang}
                                                 caseStudy={appState.getCaseStudy(userState.modalId)}/>
                                    </Modal>
                                    }
                                </Fragment>
                            </AnimateSharedLayout>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        );
    }

}
