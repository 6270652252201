import React, {FunctionComponent, Fragment, useState} from 'react';
import {observer} from "mobx-react";
import {LangMap} from "./EditableStates";
import ReactMarkdown from "react-markdown";

type EditableRichTextProps = {
    editMode: boolean;
    lang: string,
    map: LangMap,
    field: string,
    defaultValue?: string,
}

const EditableRichText: FunctionComponent<EditableRichTextProps> = ({editMode, lang, map, field, defaultValue}) => {
    const [isEditing, setIsEditing] = useState(false);

    if (!editMode) {
        return (<div className="EditableRichText">
            <ReactMarkdown source={map.get(lang, field)}/>
        </div>);
    }

    if (!map.get(lang, field)) {
        //Note disabling this for now...
        //this solves the issue that currently the default values are being written even if the user wants it to be blank...
        // map.set(lang, field, defaultValue || '?');
    }
    if (isEditing) {
        return (<div className="EditableRichText">
            <textarea rows={12} value={map.get(lang, field)} onInput={(e) => {
                map.set(lang, field, (e.target as HTMLInputElement).value);
            }}/>
            <button onClick={() => setIsEditing(false)}>
                <span role="img" aria-label="Checkmark">✔️</span>
            </button>
        </div>);
    } else {
        return (<div className="EditableRichText">
            <ReactMarkdown source={map.get(lang, field)}/>
            <button onClick={() => setIsEditing(true)}>
                <span role="img" aria-label="Edit">🖊</span>
            </button>
        </div>);
    }

};

export default observer(EditableRichText);
