import React, {MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import {AppState, ContentBlockModel, ImageModel} from "../../AppState";
import EditableText from "../../editables/EditableText";
import EditableImage from "../../editables/EditableImage";
import EditableRichText from "../../editables/EditableRichText";
import Repeater, {ListableDiv} from "../../editables/Repeater";

@observer
export default class ContentBlock extends React.Component<{appState: AppState, contentBlock: ContentBlockModel, imageFirst: boolean, onMouseEnter?:MouseEventHandler, onMouseLeave?:MouseEventHandler}, {}> {
    render() {
        const {contentBlock, onMouseEnter, onMouseLeave, appState, imageFirst} = this.props;
        return (
            <div id={contentBlock.uid} className="ContentBlock container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="row">
                    <div className="col-sm-4">
                        <h1><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={contentBlock.textValues} field="header" defaultValue="Section Name"/></h1>
                    </div>
                    <div className="col-sm-8">
                        <h1><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={contentBlock.textValues} field="callout" defaultValue="Overview..."/></h1>
                    </div>
                </div>
                <div className="ContentBlock_body">
                    <div className="row">
                        <div className="col-sm-8 offset-4">
                            {!imageFirst && <EditableRichText editMode={appState.editableMode} lang={appState.activeLang} map={contentBlock.textValues} field="body" defaultValue="Details..."/>}
                            <Repeater list={contentBlock.imageState}
                                      create={() => new ImageModel({})}
                                      eachRender={(imageModel, index) =>
                                          <ListableDiv key={`learn_image_${imageModel.uid}`}>
                                              <EditableImage urlPrefix={appState.imageUrlPrefix} lang={appState.activeLang} imageHandler={appState.imageHandler} editMode={appState.editableMode} width={150} height={100} imageState={imageModel.imageState}/>
                                          </ListableDiv>

                                      }/>
                            {imageFirst && <EditableRichText editMode={appState.editableMode} lang={appState.activeLang} map={contentBlock.textValues} field="body" defaultValue="Details..."/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
