import React, {Fragment, FunctionComponent} from 'react';
import {observer} from "mobx-react";
import {UndoManager} from "mobx-keystone/dist/actionMiddlewares/undoMiddleware";
import {applySnapshot, fromSnapshot, getSnapshot} from "mobx-keystone";
import {AppState, UserState} from "../AppState";
import DataManager, {IDataManager} from "../data/DataManager";
import SimpleSelect from "../components/core/SimpleSelect";


const Persistence: FunctionComponent<{
    undoManager: UndoManager,
    appState: AppState,
    userState: UserState,
    dataManager: IDataManager,
}> = ({undoManager, appState, dataManager, userState}) => {

    return (
        <Fragment>
            <div className="floating">
                <button disabled={!undoManager.canUndo} onClick={() => {
                    undoManager.undo();
                }}>Undo
                </button>
                <button disabled={!undoManager.canRedo} onClick={() => {
                    undoManager.redo();
                }}>Redo
                </button>
                <button onClick={() => {
                    dataManager.saveCurrentState();
                    appState.caseStudies.forEach((caseStudy, i) => {
                        if (caseStudy.docRef.hasChanges && caseStudy.docRef.docDataManager) {
                            caseStudy.docRef.docDataManager.saveCurrentState();
                        }
                    });
                    //localStorage.setItem('persistence', JSON.stringify(getSnapshot(appState)));
                }}>Save
                </button>
                <button onClick={() => {
                    dataManager.reload();
                }}>Load
                </button>
                <div className="lang-picker">
                    <SimpleSelect
                        value={appState.activeLang}
                        setter={(selectedVal) => {
                            appState.activeLang = selectedVal;
                        }}
                        options={[
                            {value: 'en', label: 'English'},
                            {value: 'es', label: 'Español'},
                            {value: 'zh', label: '中文'},
                        ]}
                    />
                </div>
                <div className="draft-options">
                    <label>
                        <input type="checkbox" checked={userState.showDraftCaseStudies}
                               onClick={() => userState.showDraftCaseStudies = !userState.showDraftCaseStudies}/> Include
                        Drafts
                    </label>
                </div>
            </div>
        </Fragment>
    );
};

export default observer(Persistence);

