import React, {FunctionComponent} from "react";
import Select from "react-select";
import {CaseStudy} from "../../AppState";
import EditableSpan from "../../editables/EditableSpan";
import {observer} from "mobx-react";

const MetricDisplay: FunctionComponent<{
    editMode: boolean,
    metric: {
        // className: string,
        // subtext: string,
        getter: (d: CaseStudy) => number,
        setter: (d: CaseStudy, value: number) => void,
        // total: number,
        formatter: (d: number) => string,
    },
    caseStudy: CaseStudy,
}> = ({editMode, metric, caseStudy}) => {
    return <EditableSpan
        editMode={editMode}
        getter={() => {
            if (editMode) {
                return metric.getter(caseStudy).toLocaleString();
            }
            return metric.formatter(metric.getter(caseStudy));
        }}
        setter={(value: string) => {
            const val = parseFloat(value);
            metric.setter(caseStudy, val);
        }}
    />
}

export default observer(MetricDisplay);
