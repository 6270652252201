import {ImageState, LangMap, LookupLinks, LookupList} from "./editables/EditableStates";
import {computed} from 'mobx';
import {
    fromSnapshot,
    Model,
    model, modelAction,
    prop,
    registerRootStore,
    getRoot, getParent, Ref, rootRef, detach, onPatches, types, modelFlow, _await,
} from "mobx-keystone";
import {EditableToolbarState} from "./editables/EditableToolbarState";
import IImageHandler from "./editables/data/IImageHandler";
import lunr, {Index} from "lunr";
import {createBrowserHistory, History, Location} from "history";
import {matchPath} from "react-router-dom";
import {DocRefManager} from "./data/DataManager";
import bbox from '@turf/bbox';
import {BBox, polygon, point} from '@turf/helpers';

//TODO not sure which is cleaner... doing all array manipulations from with a keystone class action
//or to use a generic wrapper class that can be targeted from anywhere
//could be used with factory model to create generics... https://mobx-keystone.js.org/classModels#factory-pattern
// @model("rfe/ArrayState")
// export class ArrayState<T> extends Model({
//     values: prop<T[]>(() => []),
//
// }) {
//
// }

// @model("rfe/SectionState")
// export class SectionState extends Model({
//     color: prop<string>('#ffffff', {setterAction: true}),
//     textValues: prop<LangMap>(() => new LangMap({}), {}),
//     cards: prop<CaseStudy[]>(() => []),
// }) {
//     get uid() {
//         return this.$modelId;
//     }
// }

// export enum ScaleClassification {
//     Block = "Block",
//     City = 'City',
//     Neighborhood = "Neighborhood",
//     District = "District",
// }

function toFixed(v: number, length: number) {
    return parseFloat(v.toFixed(length));
}

export function syncQueryParams(appState: AppState): void {
    const params = new URLSearchParams(window.location.search);
    const selection = JSON.parse(params.get('compare') || '[]');

    selection.forEach((id: string) => {
        const study = appState.findCaseStudy(id);
        if (study && !study.selected) {
            appState.toggleSelectedCaseStudy(study);
        }
    });

    appState.selectedCaseStudies.forEach((caseStudy: CaseStudy) => {
        if (selection.indexOf(caseStudy.uid) === -1) {
            selection.push(caseStudy.uid);
        }
    });

    if (selection.length > 0) {
        params.set('compare', JSON.stringify(selection));
        window.history.pushState({}, document.title, `${window.location.pathname}?${params.toString()}`);
    }
}


export enum ViewMode {
    Card,
    Row,
    Map,
    Compare
}

export enum PageMode {
    Home,
    Explore,
    Measure,
    Learn,
    About,
}

export enum Unit {
    Acre = 'Acre',
    Hectare = "Hectare",
}

export enum ScaleIds {
    City = '8nol7hc1ldlqfi',
    District = 'cj794hxnmd8ibd',
    Neighborhood = 'p9qbbtq4egzhil',
    Block = 'nys10botvrha9n',
}

const orderedScales: string[] = [
    ScaleIds.City,
    ScaleIds.District,
    ScaleIds.Neighborhood,
    ScaleIds.Block,
];

const CaseStudyRef = rootRef<CaseStudy>("rfe/CaseStudyRef", {
    getId(maybeCaseStudy) {
        return maybeCaseStudy instanceof CaseStudy ? maybeCaseStudy.uid : undefined
    },
    onResolvedValueChange(ref, newCaseStudy, oldCaseStudy) {
        if (oldCaseStudy && !newCaseStudy) {
            detach(ref)
        }
    },
})

@model("rfe/LatLng")
export class LatLng extends Model({
    lat: prop<number>(0, {}),
    lng: prop<number>(0, {}),
}) {

}

@model("rfe/DocData")
export class DocData extends Model({
    content: prop<ContentBlockModel[]>(() => []),
}) {
    @modelAction
    addContent(contentBlock: ContentBlockModel) {
        this.content.push(contentBlock);
    }

    @modelAction
    prependContent(contentBlock: ContentBlockModel) {
        this.content.unshift(contentBlock);
    }

    @modelAction
    addContentBlock(lang: string, content: string) {
        const contentBlockModel = new ContentBlockModel({});
        contentBlockModel.textValues.set(lang, 'body', content);
        this.addContent(contentBlockModel);
        return contentBlockModel;
    }
}

// const someModelType = (SomeModel)
//types.model<T>
@model("rfe/DocRef")
export class DocRef extends Model({
    collectionName: prop<string>('docs', {}),
    documentId: prop<string>('', {}),
    loaded: prop<boolean>(false, {setterAction: true}),
    loading: prop<boolean>(false, {setterAction: true}),
}) {
    @computed
    get hasChanges() {
        return this.loaded;//TODO implement check for changes...
    }

    @modelAction
    ensureLoaded() {
        if (this.data) return;
        if (this.loading) return;

        this.loading = true;
        this.getContent().then((docData) => {
            this.loading = false;
            if (docData && docData.$modelType === 'rfe/DocData') {
                this.data = docData;
            } else {
                this.data = new DocData({});//failed load - insert default
            }
        });
    }

    //Note: content is a volatile class member - and not serialized
    data?: DocData;
    docDataManager?: DocRefManager;

    // loaded: boolean = false;
    // loading: boolean = false;

    @computed
    get isLoading() {
        return this.loading;
    }

    @computed
    get isLoaded() {
        return this.loaded;
    }

    async fetchContentAsync() {
        this.docDataManager = new DocRefManager(this);
        // we use `yield* _await(X)` where we would use `await X`
        // note: it is `yield*`, NOT just `yield`; `_await` is a function that has to be imported
        const snapshot: any = await this.docDataManager.getContent();
        if (snapshot) this.data = fromSnapshot<DocData>(snapshot);
    }

    async getContent(): Promise<DocData> {
        await this.fetchContentAsync();
        //TODO -- how does a component that needs to render this content render using await?
        //consider using useEffect / componentDidMount
        if (!this.data) throw new Error('could not load content');
        return this.data;
    }
}


@model("rfe/CaseStudy")
export class CaseStudy extends Model({
    cvg: prop<number>(-1, {}),
    dud: prop<number>(-1, {}),
    far: prop<number>(-1, {}),
    pop: prop<number>(-1, {}),

    siteArea: prop<number>(-1, {setterAction: true}),
    grossBuildingArea: prop<number>(-1, {setterAction: true}),

    caseStudyYear: prop<number>(-1, {setterAction: true}),
    dwellingUnits: prop<number>(-1, {setterAction: true}),
    parkingSpaces: prop<number>(-1, {setterAction: true}),
    population: prop<number>(-1, {setterAction: true}),

    // scaleClassification: prop<ScaleClassification>(() => ScaleClassification.Block, {setterAction: true}),
    rangeOfHeights: prop<string>('', {setterAction: true}),
    draftMode: prop<boolean>(false, {setterAction: true}),

    position: prop<LatLng>(() => new LatLng({}), {}),
    geometry: prop<string>('', {setterAction: true}),

    imageStates: prop<ImageState[]>(() => [new ImageState({})], {}),//TODO remove after data update
    heroImage: prop<ImageState>(() => new ImageState({}), {setterAction: true}),

    textValues: prop<LangMap>(() => new LangMap({}), {}),
    docRef: prop<DocRef>(() => new DocRef({}), {}),
    lookups: prop<LookupLinks>(() => new LookupLinks({}), {})
}) {
    get uid() {
        return this.$modelId;
    }

    @modelAction
    setDocRef(docRef: DocRef) {
        this.docRef = docRef;
    }

    @modelAction
    clearImageStates() {
        this.imageStates.length = 0;
    }

    @modelAction
    getHeroImage(): ImageState {
        return this.heroImage;
        // if (this.imageStates.length === 0) {
        //     this.imageStates.push(new ImageState({}));
        // }
        // return this.imageStates[0];
    }

    get CVG() {
        return this.cvg;
    }

    get Geometry() {
        return this.geometry;
    }

    @modelAction
    setCVG(cvg: number) {
        this.cvg = toFixed(cvg, 1);
    }

    @computed
    get activeUnitsRatio() {
        return getRoot(this).activeUnitsRatio;
    }

    get DUD() {
        return this.dud * this.activeUnitsRatio;
    }

    @modelAction
    setMetric(metricId: string, value: number) {
        switch (metricId) {
            case 'pop':
                this.setPOP(value);
                break;
            case 'dud':
                this.setDUD(value);
                break;
            case 'far':
                this.setFAR(value);
                break;
            case 'cvg':
                this.setCVG(value);
                break;
        }
    }

    @modelAction
    setDUD(dud: number) {
        this.dud = toFixed(dud / this.activeUnitsRatio, 2);
    }

    get POP() {
        return this.pop * this.activeUnitsRatio;
    }

    @modelAction
    setPOP(pop: number) {
        this.pop = toFixed(pop / this.activeUnitsRatio, 2);
    }

    get FAR() {
        return this.far;
    }

    @modelAction
    setFAR(far: number) {
        this.far = toFixed(far, 2);
    }

    @computed get selected() {
        return getRoot(this).appState.selectedCaseStudies.includes(this)
    }

    @computed get name() {
        return this.textValues.get(getRoot(this).appState.activeLang, 'name');
    }

    @modelAction
    setName(value: string) {
        this.textValues.set(getRoot(this).appState.activeLang, 'name', value);
    }

    @computed get cityName(): string {
        let appState: AppState = getRoot(this).appState;
        return this.lookups.get(appState.linkedObjects, appState.activeLang, 'location');
    }

    @computed get scaleClassification(): string {
        return this.lookups.getKey('scale') || '';
    }

    @computed
    get spatialData() {
        const geometry = this.geometry && JSON.parse(this.geometry)//geometry stored as string to simplify document for firestore

        const pos = this.position || {lng: '-71.1895713', lat: '42.365917'};
        const pt = point([pos.lng, pos.lat]);
        let bounds: BBox = bbox(pt);
        bounds.forEach((v, i) => {
            bounds[i] += Math.random() * 0.01;//prevent zero bounds
        });
        if (geometry.coordinates && geometry.coordinates.length > 0) {
            this.ensureClosedPoly(geometry.coordinates[0]);
            bounds = bbox(polygon(geometry.coordinates));
        } else {
            console.log('No valid geometry, using points');
        }
        return {center: this.position, geometry, bounds}
    }

    private ensureClosedPoly(coordinates: any[]) {
        const first = coordinates[0];
        const last = coordinates[coordinates.length - 1];
        if (first[0] !== last[0] || first[1] !== last[1]) {
            coordinates.push(first);
        }
    }
}

@model("rfe/ImageModel")
export class ImageModel extends Model({
    imageState: prop<ImageState>(() => new ImageState({}), {setterAction: true}),
    textValues: prop<LangMap>(() => new LangMap({}), {})
}) {
    get uid() {
        return this.$modelId;
    }
}

@model("rfe/ContentBlock")
export class ContentBlockModel extends Model({
    textValues: prop<LangMap>(() => new LangMap({}), {}),
    imageState: prop<ImageModel[]>(() => []),
    type: prop<string>('ContentBlock', {setterAction: true}),
}) {
    get uid() {
        return this.$modelId;
    }

    @modelAction
    addImageState(imageState: ImageModel) {
        this.imageState.push(imageState);
    }
}

@model('rfe/FilterState')
export class FilterState extends Model({
    scale: prop<string>('POP', {setterAction: true}),
    searchQuery: prop<string>('', {setterAction: true}),
    metric: prop<string | null>(ScaleIds.Neighborhood, {setterAction: true}),
}) {
    searchResult: Index.Result[] | undefined;

    idx?: lunr.Index;

    @computed
    get matchedIds(): string[] {
        const matchedIds: string[] = [];

        if (this.idx && this.searchQuery) {
            const searchResult = this.idx.search(this.searchQuery);
            if (searchResult) {
                // console.log('search results..found..');
                searchResult.forEach((matched: any, index: number) => {
                    // console.log(matched.ref.toString());
                    matchedIds.push(matched.ref.toString());
                });
                // console.log('this.filter.searchResult', searchResult);
            }
        }

        return matchedIds;
    }

    @modelAction
    generateIndex(caseStudies: CaseStudy[], appState: AppState) {
        let indexDocs: any = [];
        caseStudies.forEach((caseStudy: CaseStudy, index: number) => {
            //console.log(caseStudy.textValues);
            if (caseStudy.textValues) {
                let indexDoc = {
                    id: caseStudy.$modelId,
                    name: caseStudy.name,
                    cityName: caseStudy.cityName,
                    content: caseStudy.textValues.get(appState.activeLang, 'content')
                };

                indexDocs.push(indexDoc);
            }
        });

        this.idx = lunr(function () {
            this.field('name')
            this.field('cityName')
            this.field('content')
            indexDocs.forEach((value: any, index: number) => {
                this.add(value);
            })
        });
    }
}

@model("rfe/AppState")
export class AppState extends Model({
    textValues: prop<LangMap>(() => new LangMap({}), {}),
    terms: prop<LangMap>(() => new LangMap({}), {}),
    banner: prop<ImageModel[]>(() => []),
    metricsBuildingMode: prop<string>('center', {setterAction: true}),
    caseStudies: prop<CaseStudy[]>(() => []),
    learnContent: prop<ContentBlockModel[]>(() => []),
    aboutContent: prop<ContentBlockModel[]>(() => []),
    measureContent: prop<ContentBlockModel[]>(() => []),
    activeLang: prop<string>('en', {setterAction: true}),
    imageUrlPrefix: prop<string>('https://storage.googleapis.com/densityatlas-b3dbd.appspot.com/images/', {setterAction: true}),
    loaded: prop<boolean>(false, {setterAction: true}),
    user: prop<string>('', {setterAction: true}),
    editableMode: prop<boolean>(false, {setterAction: true}),
    mobileMode: prop<boolean>(false, {setterAction: true}),
    linkedObjects: prop<LookupList>(() => new LookupList({}), {}),
    unit: prop<Unit>(Unit.Hectare, {setterAction: true}),
    selectedCaseStudyRefs: prop<Ref<CaseStudy>[]>(() => []),
    dummyCaseStudy: prop<CaseStudy>(() => new CaseStudy({})),
}) {
    history?: History<History.LocationState>;

    constructor(data: any, browserMode = true) {
        super(data);
        if (browserMode) {
            this.history = createBrowserHistory();
        }
    }


    @modelAction
    initialize() {
        //use a dummy 'CaseStudy' that will be represented by the DiagramBlock
        const caseStudy = new CaseStudy({});
        this.dummyCaseStudy = caseStudy;
        //set all numbers to mid-way along the 'slider'
        this.metricFormatters.map(metric => caseStudy.setMetric(metric.className, metric.total / 2));

        //TODO update on window resize...
        const mobileBreakpoint = 768;
        this.mobileMode = (document.documentElement && document.documentElement.clientWidth < mobileBreakpoint);
        window.addEventListener('resize', ()=> {
            this.mobileMode = (document.documentElement && document.documentElement.clientWidth < mobileBreakpoint);
        });
    }

    localize(term: string): string {
        return this.terms.get(this.activeLang, `${term}.${this.activeLang}`);//todo store terms by lang
    }

    findCaseStudy(id: string) {
        return this.caseStudies.filter(study => study.uid === id)[0];
    }

    @computed
    get selectedCaseStudies(): any {
        return this.selectedCaseStudyRefs.map(r => r.current)
    }

    @modelAction
    toggleSelectedCaseStudyById(uid: string) {
        let caseStudy = this.getCaseStudy(uid);
        if (!caseStudy) return;
        this.toggleSelectedCaseStudy(caseStudy);
    }

    @modelAction
    toggleSelectedCaseStudy(caseStudy: CaseStudy) {
        const params = new URLSearchParams(window.location.search);
        const selection = JSON.parse(params.get('compare') || '[]');

        if (!this.selectedCaseStudies.includes(caseStudy)) {
            if (selection.indexOf(caseStudy.uid) === -1) {
                selection.push(caseStudy.uid);
            }
            this.selectedCaseStudyRefs.push(CaseStudyRef(caseStudy))
        } else {
            selection.splice(selection.indexOf(caseStudy.uid), 1);
            const caseStudyRefIndex = this.selectedCaseStudyRefs.findIndex(CaseStudyRef => CaseStudyRef.maybeCurrent === caseStudy)
            if (caseStudyRefIndex >= 0) {
                this.selectedCaseStudyRefs.splice(caseStudyRefIndex, 1)
            }
        }

        params.set('compare', JSON.stringify(selection));
        window.history.pushState({}, document.title, `${window.location.pathname}?${params.toString()}`);
    }

    @modelAction
    clearSelection() {
        this.selectedCaseStudyRefs = [];
    }

    @modelAction
    setTerms(terms: any) {
        this.terms.setFromObject(terms);
    }

    @modelAction
    applySnapshot(snapshot: any) {
        //NOTE: using applySnapshot directly on AppState doesn't seem to work because of mismatched IDs when applying a saved one to a newly created one...
        if (snapshot.textValues) this.textValues = fromSnapshot<LangMap>(snapshot.textValues);
        if (snapshot.terms) this.terms = fromSnapshot<LangMap>(snapshot.terms);
        this.banner = fromSnapshot<ImageModel[]>(snapshot.banner);
        this.caseStudies = fromSnapshot<CaseStudy[]>(snapshot.caseStudies);
        if (snapshot.learnContent) this.learnContent = fromSnapshot<ContentBlockModel[]>(snapshot.learnContent);
        if (snapshot.measureContent) this.measureContent = fromSnapshot<ContentBlockModel[]>(snapshot.measureContent);
        if (snapshot.aboutContent) this.aboutContent = fromSnapshot<ContentBlockModel[]>(snapshot.aboutContent);
        if (snapshot.linkedObjects) this.linkedObjects = fromSnapshot<LookupList>(snapshot.linkedObjects);

        this.activeLang = snapshot.activeLang;//Note - just testing. This doesn't need to persist (language would differ per user)

        //when we save the main doc, the loaded prop can persist, but this is no longer true...
        this.caseStudies.forEach((caseStudy, i) => {
            caseStudy.docRef.loaded = false;
        });

    }

    setUser(user = '') {
        this.user = user;
    }

    @computed
    get geojsonPoygons() {
        return {
            "type": "FeatureCollection",
            "features": this.caseStudies.map((caseStudy) => {
                    return {
                        "type": "Feature",
                        "properties": {},
                        "geometry": caseStudy.spatialData.geometry
                    }
                }
            ),
        };
    }

    @computed
    get geojsonPoints() {
        return {
            "type": "FeatureCollection",
            "features": this.includedCaseStudies.map((caseStudy) => {
                    return {
                        "type": "Feature",
                        "properties": {
                            name: caseStudy.name,
                            uid: caseStudy.uid,
                            selected: caseStudy.selected
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [
                                caseStudy.spatialData.center.lng,
                                caseStudy.spatialData.center.lat,
                            ]
                        }
                    }
                }
            ),
        };
    }

    @computed get metricFormatters() {
        const format = (d: number) => `${d < 0 ? '- ' : Math.round(d)}/${getRoot(this).userState.unit === Unit.Acre ? 'ac' : 'ha'}`;

        const getMetricFormatter = (className: string,
                                    subtext: string,
                                    getter: (d: CaseStudy) => number,
                                    setter: (d: CaseStudy, value: number) => void,
                                    total: number,
                                    useUnits: boolean,
                                    formatter = format) => {

            if (useUnits) {
                total = total * getRoot(this).activeUnitsRatio;
            }

            return {
                className: className,
                getter: getter,
                setter: setter,
                total: total,
                subtext: subtext,
                formatter: formatter,
            }
        };
        return [
            getMetricFormatter('pop', 'POP', (d: CaseStudy) => d.POP, (d: CaseStudy, value: number) => d.setPOP(value), 4000, true),
            getMetricFormatter('dud', 'DUD', (d: CaseStudy) => d.DUD, (d: CaseStudy, value: number) => d.setDUD(value), 900, true),
            getMetricFormatter('far', 'FAR', (d: CaseStudy) => d.FAR, (d: CaseStudy, value: number) => d.setFAR(value), 15, false, (d: number) => `${d === -1 ? '- ' : Math.round(100 * d) / 100}`),
            getMetricFormatter('cvg', 'CVG', (d: CaseStudy) => d.CVG, (d: CaseStudy, value: number) => d.setCVG(value), 100, false, (d: number) => `${d === -1 ? '- ' : Math.round(d) + '%'}`),

        ];
    }

    @computed get scaleOptions() {
        const scaleOptions = this.linkedObjects.getOptions(this.activeLang, 'scale');
        scaleOptions.sort((a, b) => {
            return orderedScales.indexOf(a.value) - orderedScales.indexOf(b.value);
        });
        return scaleOptions;
    }

    @computed get caseStudiesByMetric() {
        return this.includedCaseStudies.reduce((scales: any, caseStudy) => {
            if (!scales[caseStudy.scaleClassification]) {
                scales[caseStudy.scaleClassification] = [];
            }

            scales[caseStudy.scaleClassification].push(caseStudy);

            return scales;
        }, {});
    }

    @computed get includedCaseStudies() {
        const {showDraftCaseStudies} = getRoot(this).userState;

        let caseStudies = this.caseStudies;
        if (!showDraftCaseStudies) {
            caseStudies = caseStudies.filter(study => !study.draftMode);
        }
        return caseStudies;
    }

    @computed get filteredCaseStudies() {
        let caseStudies = this.includedCaseStudies;
        const {filter} = getRoot(this).userState;
        const {metric, scale, searchQuery, matchedIds} = filter;

        if (metric) {
            caseStudies = caseStudies.filter(study => study.scaleClassification === metric);
        }
        if (searchQuery) {
            caseStudies = caseStudies.filter(study => matchedIds.includes(study.$modelId));
        }

        caseStudies = caseStudies.sort((a, b) => {

            if (!metric) {
                const scaleComp = a.scaleClassification.localeCompare(b.scaleClassification);
                return scaleComp === 0 ? a.name.localeCompare(b.name) : scaleComp;
            }

            const scaleComp = a.scaleClassification.localeCompare(b.scaleClassification);
            if (scaleComp === 0) {
                switch (scale) {
                    case 'POP':
                        return a.pop >= b.pop ? -1 : 1;
                    case 'DUD':
                        return a.dud >= b.dud ? -1 : 1;
                    case 'CVG':
                        return a.cvg >= b.cvg ? -1 : 1;
                    case 'FAR':
                        return a.far >= b.far ? -1 : 1;

                }
                return 0;
            }
            return -scaleComp;
        });

        return caseStudies;
    }

    public imageHandler: IImageHandler | undefined;

    getCaseStudy(uid: string): CaseStudy | undefined {
        // console.log(`GETTING CASE STUDY: ${uid}`);
        return this.caseStudies.find(study => study.uid === uid);
    }
}

@model('rfe/UserState')
export class UserState extends Model({
    unit: prop<Unit>(Unit.Hectare, {setterAction: true}),
    listScrollProgress: prop<number>(-1, {setterAction: true}),//TEMP
    viewMode: prop<ViewMode>(ViewMode.Row, {setterAction: true}),//TEMP
    pageMode: prop<PageMode>(PageMode.Home, {setterAction: true}),//TEMP
    modalId: prop<string>('', {setterAction: true}),
    counter: prop<number>(0, {setterAction: true}),//TEMP
    filter: prop<FilterState>(() => new FilterState({})),
    showDraftCaseStudies: prop<boolean>(false, {setterAction: true}),
}) {
    history?: History<History.LocationState>;

    initialize(appState: AppState) {
        if (!appState.history) return;
        this.history = appState.history;
        appState.history.listen((location) => {
            // console.log(location)
            this.matchMainRoute(location);
            this.matchExploreRoute(location);
            syncQueryParams(appState);
        });
        this.matchMainRoute(this.history.location);
        this.matchExploreRoute(this.history.location);
    }

    closeModal() {
        if (!this.history) return;
        this.history.push(this.lastViewUrl);
    }

    navigateDefault() {
        if (!this.history) return;
        this.history.push('/explore/cards');
    }

    @modelAction
    matchMainRoute(location: Location<{} | null | undefined>) {
        const match = matchPath(location.pathname, {
            path: '/:page',
            exact: false,
            strict: true//trailing slashes
        });
        if (!match) {
            this.setPageMode('');
            return;
        }
        const params: any = match.params;
        this.setPageMode(params.page);
    }

    @modelAction
    matchExploreRoute(location: Location<{} | null | undefined>) {
        const match = matchPath(location.pathname, {
            path: ["/explore/:style/:uid", "/explore/:style"],
            exact: true,
            strict: true
        });
        if (!match) return;
        const params: any = match.params;
        this.setViewMode(match.url);
        if (params.style === 'details') {
            this.modalId = decodeURIComponent(params.uid);
        } else {
            this.modalId = '';
        }
    }

    @computed
    get lastViewUrl(): string {
        switch (this.viewMode) {
            case ViewMode.Card:
                return '/explore/cards'
            case ViewMode.Row:
                return '/explore/rows'
            case ViewMode.Map:
                return '/explore/map'
            case ViewMode.Compare:
                return '/explore/compare'
        }
        return '/';
    }

    @modelAction
    setViewMode(url: string) {
        switch (url) {
            case '/explore/cards':
                this.viewMode = ViewMode.Card;
                break;
            case '/explore/rows':
                this.viewMode = ViewMode.Row;
                break;
            case '/explore/map':
                this.viewMode = ViewMode.Map;
                break;
            case '/explore/compare':
                this.viewMode = ViewMode.Compare;
                break;
        }
    }

    @modelAction
    setPageMode(page: string) {
        switch (page) {
            case 'about':
                this.pageMode = PageMode.About;
                break;
            case 'measure':
                this.pageMode = PageMode.Measure;
                break;
            case 'learn':
                this.pageMode = PageMode.Learn;
                break;
            case 'explore':
                this.pageMode = PageMode.Explore;
                break;
            default:
                this.pageMode = PageMode.Home;
                break;

        }
    }

}


@model("rfe/RootStore")
export class RootStore extends Model({
    appState: prop<AppState>(),
    editableToolbarState: prop<EditableToolbarState>(() => new EditableToolbarState({})),
    userState: prop<UserState>(() => new UserState({}), {}),
}) {
    @computed
    get activeUnitsRatio() {
        return (this.userState.unit === Unit.Acre ? 2.47105 : 1);
    }
}


export function createRootStore(browserMode = true): RootStore {
    const appState = new AppState({
        // sections: [new SectionState({
        //     color: '#ffe8b1',
        // })]
    }, browserMode);

    const rootStore = new RootStore({appState});
    registerRootStore(rootStore);
    appState.initialize();
    rootStore.userState.initialize(appState);
    // rootStore.userState.filter.generateIndex();

    // setInterval(() => {
    //     rootStore.userState.counter++;
    // }, 5000);

    if (browserMode) {
        const disposer = onPatches(appState, (patches, inversePatches) => {
            patches.forEach((patch) => {
                console.log('PATCHES', patch.op, patch.path.join('.'), patch);
                if (patch.path[0] === 'caseStudies') {
                    rootStore.userState.filter.generateIndex(appState.caseStudies, appState);
                }
            });
        });
    }

    return rootStore;
}

