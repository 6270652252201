import React, {MouseEventHandler} from 'react';
import {observer} from "mobx-react";
import {Link, NavLink, useLocation} from "react-router-dom";
import EditableText from "../editables/EditableText";
import {AppState} from "../AppState";

@observer
export default class NavBar extends React.Component<{ appState: AppState }, {}> {
    render() {
        const {appState} = this.props;
        return (
            <div className={`NavBar${appState.editableMode ? ' editable' : ''}`}>
                <nav>
                    <ul>
                        <li className="logo-link">
                            {/*<Link to="/"><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={appState.terms} field="nav.home"/></Link>*/}
                            <NavLink activeClassName='home-active' to="/"><img src="/img/Density Atlas Logo.svg" /></NavLink>
                        </li>

                        <li>
                            <NavLink activeClassName='is-active' to="/about"><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={appState.terms} field="nav.about"/></NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='is-active' to="/learn"><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={appState.terms} field="nav.learn"/></NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='is-active' to="/measure"><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={appState.terms} field="nav.measure"/></NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='is-active' to="/explore/cards" isActive={(match, location) => location.pathname.startsWith('/explore')}><EditableText editMode={appState.editableMode} lang={appState.activeLang} map={appState.terms} field="nav.explore"/></NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}
